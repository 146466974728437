import React, { Component } from 'react'

class ListeningResults extends Component {

    constructor(props){
        super(props);
        this.state={
            listening: this.props.listening
        }
        this.renderlistening = this.renderlistening.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.listening!==this.props.listening){
            this.setState({
                listening: this.props.listening
            })
        }
    }

    renderlistening(){
        var mcqsr, mcqmr, fillblanks, matrix, heading, matching,
        singlepart,writing,speaking,cuecard,parahead,labelblanks,labelheading;

        mcqsr = this.state.listening.filter((result) => {return result.parttype==="MCQ Single Right"});
        mcqmr = this.state.listening.filter((result) => {return result.parttype==="MCQ Multiple Right"});
        fillblanks = this.state.listening.filter((result) => {return result.parttype==="Fill Blanks"})
        matrix = this.state.listening.filter((result) => {return result.parttype==="Matrix"})
        heading = this.state.listening.filter((result) => {return result.parttype==="List of Headings"})
        matching = this.state.listening.filter((result) => {return result.parttype==="Matching"})        
        singlepart = this.state.listening.filter((result) => {return result.parttype==="Single Part Multiple Question"})
        writing = this.state.listening.filter((result) => {return result.parttype==="Writing"})
        speaking = this.state.listening.filter((result) => {return result.parttype==="Speaking"})
        cuecard = this.state.listening.filter((result) => {return result.parttype==="Cue Card Speaking"})
        parahead = this.state.listening.filter((result) => {return result.parttype==="Paragraph Headings"})
        labelblanks = this.state.listening.filter((result) => {return result.parttype==="Label Blanks"})
        labelheading = this.state.listening.filter((result) => {return result.parttype==="Label Headings"})

        var mcqsrscore=0, mcqmrscore=0, fillblanksscore=0, matrixscore=0, headingscore=0, matchingscore=0,
        singlepartscore=0,writingscore=0,speakingscore=0,cuecardscore=0,paraheadscore=0,labelblanksscore=0,labelheadingscore=0;

        for(let i =0; i<mcqsr.length; i++){
            mcqsrscore=mcqsrscore+mcqsr[i].score
        }
        for(let i =0; i<mcqmr.length; i++){
            mcqmrscore=mcqmrscore+mcqmr[i].score
        }
        for(let i =0; i<fillblanks.length; i++){
            fillblanksscore=fillblanksscore+fillblanks[i].score
        }
        for(let i =0; i<matrix.length; i++){
            matrixscore=matrixscore+matrix[i].score
        }
        for(let i =0; i<heading.length; i++){
            headingscore=headingscore+heading[i].score
        }
        for(let i =0; i<matching.length; i++){
            matchingscore=matchingscore+matching[i].score
        }
        for(let i =0; i<singlepart.length; i++){
            singlepartscore=singlepartscore+singlepart[i].score
        }
        for(let i =0; i<writing.length; i++){
            writingscore=writingscore+writing[i].score
        }
        for(let i =0; i<speaking.length; i++){
            speakingscore=speakingscore+speaking[i].score
        }
        for(let i =0; i<cuecard.length; i++){
            cuecardscore=cuecardscore+cuecard[i].score
        }
        for(let i =0; i<labelblanks.length; i++){
            labelblanksscore=labelblanksscore+labelblanks[i].score
        }
        for(let i =0; i<labelheading.length; i++){
            labelheadingscore=labelheadingscore+labelheading[i].score
        }
        for(let i =0; i<parahead.length; i++){
            paraheadscore=paraheadscore+parahead[i].score
        }

        return(
            <tbody>
                {mcqsr.length!==0 && <tr>
                    <th>MCQ Single Right</th>
                    <td>{mcqsr.length}</td>
                    <td>{mcqsrscore}</td>
                </tr>}
                {mcqmr.length!==0 && <tr>
                    <th>MCQ Multiple Right</th>
                    <td>{mcqmr.length}</td>
                    <td>{mcqmrscore}</td>
                </tr>}
                {fillblanks.length!==0 && <tr>
                    <th>Fill Blanks</th>
                    <td>{fillblanks.length}</td>
                    <td>{fillblanksscore}</td>
                </tr>}
                {matrix.length!==0 && <tr>
                    <th>Matrix</th>
                    <td>{matrix.length}</td>
                    <td>{matrixscore}</td>
                </tr>}
                {heading.length!==0 && <tr>
                    <th>List of Headings</th>
                    <td>{heading.length}</td>
                    <td>{headingscore}</td>
                </tr>}
                {matching.length!==0 && <tr>
                    <th>Matching</th>
                    <td>{matching.length}</td>
                    <td>{matchingscore}</td>
                </tr>}
                {singlepart.length!==0 && <tr>
                    <th>Single Part Multiple Question</th>
                    <td>{singlepart.length}</td>
                    <td>{singlepartscore}</td>
                </tr>}
                {writing.length!==0 && <tr>
                    <th>Writing</th>
                    <td>{writing.length}</td>
                    <td>{writingscore}</td>
                </tr>}
                {speaking.length!==0 && <tr>
                    <th>Speaking</th>
                    <td>{speaking.length}</td>
                    <td>{speakingscore}</td>
                </tr>}
                {cuecard.length!==0 && <tr>
                    <th>Cue Card Speaking</th>
                    <td>{cuecard.length}</td>
                    <td>{cuecardscore}</td>
                </tr>}
                {parahead.length!==0 && <tr>
                    <th>Paragraph Heading</th>
                    <td>{parahead.length}</td>
                    <td>{paraheadscore}</td>
                </tr>}
                {labelblanks.length!==0 && <tr>
                    <th>Label blanks</th>
                    <td>{labelblanks.length}</td>
                    <td>{labelblanksscore}</td>
                </tr>}
                {labelheading.length!==0 && <tr>
                    <th>Label Heading</th>
                    <td>{labelheading.length}</td>
                    <td>{labelheadingscore}</td>
                </tr>}
            </tbody>
        )
    }

    render() {
        return (
            <>
                {this.renderlistening()}
            </>
        )
    }
}

export default ListeningResults;