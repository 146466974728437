import React from "react";
import "../../assets/css/custom.css";

import 'react-quill/dist/quill.snow.css';

import SelectionHighlighter from 'react-highlight-selection';
import{Row} from 'reactstrap';

class SpeakingQuespara extends React.Component{

    constructor(props){
        super(props);
        this.state={
            paragraph: props.passage
        }
    }
    
    selectionHandler(selection) {
        //do something with selection
        console.log(selection);
    }

    componentDidUpdate(prevProps){
        if(prevProps.passage !== this.props.passage) {
            this.setState({paragraph: this.props.passage});
          }
    }

    render(){

        return(
            <>
                <div className={"speak-passage_"+this.props.setting}>
                    <Row style={{textAlign:"center",display:"inherit"}}>
                        <img style={{height: "250px"}}src={require("../../assets/img/Speaking_Pics/OGMTMK0.jpg")} alt="logo"/>
                    </Row>
                    <div className="speak-para" dangerouslySetInnerHTML={{ __html: this.state.paragraph }}/>
                </div>
            </>
        )
    }
}

export default SpeakingQuespara;