import React from 'react';
import "../testpanel/panel.css";

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
import ReactHtmlParser from 'react-html-parser';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}


const Option = props =>(
    <div className="custom-radio">
        <input
            style={{margin:"10px",display:"inline"}}
            type="radio"
            name={props.quenum}
            value={props.option}
            checked={props.option===props.useranswer}
            onChange={(evt)=>{props.handlechange(evt)}}
        />
        <label style={{display:"inline"}} >
            {props.option}
        </label>
    </div>
)

class EachQuesMcqSr extends React.Component{
    constructor(props){
        super(props);

        this.state={
            question: this.props.question,
            show3rdoption: false,
            show4thoption: false,
            show5thoption: false,
            useranswer: this.props.results[this.props.resultindex],
            results: this.props.results[this.props.resultindex],
            showoptions:this.props.question.questionnum===this.props.currentques,
        }
        this.renderquestion = this.renderquestion.bind(this);
        this.renderoptions = this.renderoptions.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.showques = this.showques.bind(this);
    }

    componentDidMount(){
        this.setState({
            question: this.props.question,
            useranswer: this.props.results[this.props.resultindex].useranswer,
            results: this.props.results[this.props.resultindex],
            showoptions:this.props.question.questionnum===this.props.currentques
        });
    }

    componentDidUpdate(prevProps){
        if(prevProps.question.questionnum !== this.props.question.questionnum || prevProps.currentques !== this.props.currentques) {
            this.setState({
                question: this.props.question,
                useranswer: this.props.results[this.props.resultindex].useranswer,
                results: this.props.results[this.props.resultindex],
                showoptions:this.props.question.questionnum===this.props.currentques
            });
          }
    }

    showques(quesnum){
        this.props.changeQues(quesnum);
    }

    handlechange(evt){
        console.log(evt.target.name)
        this.setState({
            useranswer: evt.target.value
        },()=>{
            //this.props.sendResults(this.state.question.questionnum, this.state.question.rightanswer, this.state.useranswer,this.state.question.answerexplain);
            var results = this.props.results;
            var quesresult = results[this.props.resultindex];
            var rightanswerlower = [];
            for(let i =0 ; i<quesresult.rightanswer.length; i++){
                rightanswerlower.push(quesresult.rightanswer[i].trim().toLowerCase())
            }
            if(rightanswerlower.includes(this.state.useranswer.trim().toLowerCase())){
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=1;
            }else{
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=0;
            }
            results[this.props.resultindex] = quesresult;
            this.props.updateResult(results);
        })
    }

    renderquestion(){
        return (<div className={this.state.showoptions?"mcq-question-active":"mcq-question"}
                    onClick={()=>{this.showques(this.state.question.questionnum)}}
                    style={{display:"flex"}}>
                    <p style={{marginBottom:"0px"}}>{this.state.question.questionnum}.</p> <p style={{marginBottom:"0px"}}>{ReactHtmlParser(this.state.question.question)}</p>
                </div>)
    }

    renderoptions(){
        const optionrows = [];
        if(this.state.question.option5!==""  &&this.state.question.option4!==null&& this.state.question.option5!==" "){
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option1} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option2} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option3} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option4} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option5} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            return optionrows;
        }
        if(this.state.question.option4 !=="" && this.state.question.option4!==null && this.state.question.option4!==" "){
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option1} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option2} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option3} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option4} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            return optionrows;
        }
        if(this.props.question.option3!=='' && this.state.question.option3!==null && this.state.question.option3!==" "){
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option1} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option2} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            optionrows.push(<Option quenum={this.state.question.questionnum} option={this.state.question.option3} handlechange={this.handlechange} useranswer={this.state.useranswer}/>)
            return optionrows;
        }
    }

    render(){
        return(
            <div className={"mcq-ques_"+this.props.setting}>
                {this.renderquestion()}
                {this.state.showoptions &&this.renderoptions()}
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(EachQuesMcqSr);