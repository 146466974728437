import React from 'react';

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

const Option = props =>(
    <div className="custom-radio">
        <input
            style={{margin:"10px",display:"inline"}}
            type="checkbox"
            value={props.option}
            name={props.quenum}
            onChange={(evt) => {props.handlechange(evt,props.option)}}
            ref={props.checkbox}
            checked={props.checkedans.includes(props.option.trim())}
        />
        <label style={{display:"inline"}} >
            {props.option}
        </label>
    </div>
)
class SinglePartMultiQues extends React.Component{

    constructor(props){
        super(props)
        this.state={
            questions:this.props.part.singlepartques,
            quesdata:this.props.quesdata,
            questionnum:this.props.currentques,
            results:this.props.results,
            useranswer:[],
            noofques: (this.props.part.endques-this.props.part.startques)+1,
            checkedans:[],
            disabled:false,
        }
        this.checkbox = React.createRef();
        this.renderquestion = this.renderquestion.bind(this);
        this.renderoptions = this.renderoptions.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.updatecheckans = this.updatecheckans.bind(this);
        this.rightans = [];
        this.newrightans = [];
    }

    componentDidMount(){
        this.updatecheckans();
    }

    componentDidUpdate(prevProps){
        if(prevProps.part.singlepartques !== this.props.part.singlepartques) {
            this.setState({
                questions: this.props.part.singlepartques,
                quesdata:this.props.quesdata,
                results: this.props.results,
                questionnum:this.props.currentques,
                noofques: (this.props.part.endques-this.props.part.startques)+1
            },()=>{this.updatecheckans();});
        }
    }

    updatecheckans(){
        this.rightans = [];
        this.newrightans = [];
        this.setState({checkedans:[],disabled:false})
        var useranswers = [];
        for(let i=this.props.part.startques-1,j=0; i<this.props.part.endques; i++,j++){
            var results = this.props.results;
            var quesresult = results[i];
            if(quesresult.useranswer){
                if(quesresult.parttype===this.props.part.parttype && (quesresult.useranswer.length>0)){
                    useranswers.push(quesresult.useranswer);
                }
            }
        }
        this.setState({checkedans: useranswers},()=>{
            for(let i =0; i<this.state.quesdata.length; i++){
                this.rightans.push(this.state.quesdata[i].rightanswer[0].trim());
            }
            console.log(this.rightans);
            this.newrightans = [];
            for(let i=0 ; i<this.rightans.length; i++){
                this.newrightans.push({
                    rightanswer: this.rightans[i].trim(),
                    found: this.state.checkedans.includes(this.rightans[i])
                })
            }
            console.log(this.newrightans);
            this.updateres()
        });
    }

    updateres(){
        var checkedanscopy = [...this.state.checkedans];
        console.log(checkedanscopy)
        for(let i=0; i<this.newrightans.length; i++){
            if(this.newrightans[i].found){
                var index = checkedanscopy.indexOf(this.newrightans[i].rightanswer.trim());
                if(index !== -1){
                    checkedanscopy.splice(index,1);
                }
            }
            if(!this.newrightans[i].found){
                if(checkedanscopy[0]){
                    this.newrightans[i] = {
                        rightanswer: this.newrightans[i].rightanswer,
                        found: false,
                        useranswer: checkedanscopy[0]
                    }
                    checkedanscopy.splice(0,1);
                }else{
                    this.newrightans[i] = {
                        rightanswer: this.newrightans[i].rightanswer,
                        found: false
                    }
                }
            }
        }
        console.log(this.newrightans);

        for(let i=this.props.part.startques-1,j=0; i<this.props.part.endques; i++,j++){
            var results = this.props.results;
            var quesresult = results[i];
            if(this.newrightans[j].found && quesresult.parttype===this.props.part.parttype){
                quesresult.useranswer = this.newrightans[j].rightanswer;
                quesresult.score=1;
            } else if(!this.newrightans[j].found && quesresult.parttype===this.props.part.parttype){
                quesresult.useranswer = this.newrightans[j].useranswer;
                quesresult.score=0;
            }
            results[i] = quesresult;
            this.props.updateResult(results); 
        }
    }

    handlechange(evt, option){
        if(evt.target.checked && !this.state.disabled){
            var answervalue = evt.target.value;
            this.setState(prevState =>({
                checkedans: [...prevState.checkedans,answervalue.trim()]
            }),()=>{
                    console.log(this.state.checkedans);
                    this.newrightans = [];
                    for(let i=0 ; i<this.rightans.length; i++){
                        this.newrightans.push({
                            rightanswer: this.rightans[i].trim(),
                            found: this.state.checkedans.includes(this.rightans[i].trim())
                        })
                    }
                    console.log(this.newrightans);
                    this.updateres();
                    if(this.state.noofques===this.state.checkedans.length){
                        this.setState({
                            disabled:true
                        })
                    }
            })
        }
        else if(!evt.target.checked){
            var answervalue = evt.target.value;
            for(let i=0 ; i<this.newrightans.length; i++){
                if(this.newrightans[i].rightanswer===answervalue){
                    this.newrightans[i].found = false;
                }
            }
            console.log(this.newrightans);
            var array = [...this.state.checkedans];
            var index = array.indexOf(answervalue)
            if (index !== -1) {
              array.splice(index, 1);
              this.setState({
                  checkedans: array
                },()=>{
                    this.updateres();
                    if(this.state.noofques===this.state.checkedans.length){
                        this.setState({disabled:true}) 
                    }else{
                        this.setState({disabled:false}) 
                    }
                });
            }  
        }
    }
    renderquestion(){
        return (<p className="mcq-question-active">
                    <div className="mcq-question-html" dangerouslySetInnerHTML={{ __html: this.state.questions}}/>
                </p>)
    }

    renderoptions(){
        const optionrows = [];
        for(var i=0;i<this.props.part.listofoptions.length;i++){
            optionrows.push(<Option 
                quenum={this.state.questionnum} 
                option={this.props.part.listofoptions[i]} 
                handlechange={this.handlechange} 
                useranswer={this.state.results[this.state.questionnum].useranswer}
                checkbox={this.checkbox}
                disabled ={this.state.disabled} 
                checkedans = {this.state.checkedans} 
                />)
        }
        return optionrows;
    }


    render(){
        return(
            <>
            <div className={"mcq-ques_"+this.props.setting}>
                {this.renderquestion()}
                {this.renderoptions()}
            </div>
            </>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(SinglePartMultiQues);