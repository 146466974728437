import React from "react";
import {Bar} from "react-chartjs-2";
import filterread from "./FilterRead";
import filterwrite from "./Writingfield";
const options = {
    legend: {
        display: true,
        position:'right',
        align:'middle'
    },

    scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true,
            ticks: {
                beginAtZero: true
            }
        }]
    }
}

class Barchart extends React.Component{
    constructor(props){
        super(props);
        this.state={
            results:this.props.results,
            selectedcategory:this.props.selectedcategory,
            data:null
        }
        this.setdata = this.setdata.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedcategory!==this.props.selectedcategory){
            this.setState({
                results:this.props.results,
                selectedcategory:this.props.selectedcategory
            },()=>{
                this.setdata();
            })
        }
    }

    componentDidMount() {
        this.setdata();
    }

    async setdata(){
        if(this.state.selectedcategory==="Reading" || this.state.selectedcategory==="Listening" || this.state.selectedcategory==="Full Length Test"){
            var filtereddata = await filterread(this.state.results, this.state.selectedcategory);
            this.setState({data:filtereddata})
        }
        if(this.state.selectedcategory==="Writing"){
            var filtereddata = await filterwrite(this.state.results, this.state.selectedcategory);
            this.setState({data:filtereddata})    
        }
    }

    render(){
        return(
            <Bar data={this.state.data} options={options}/>
        )
    }
}

export default Barchart;