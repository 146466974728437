import React, { Component } from 'react'
import {
    Card,
    CardHeader,
    NavLink,
    Nav,
    Row,
    TabContent,
    TabPane,
    Table,
    Pagination, 
    PaginationItem, 
    PaginationLink
}from "reactstrap";

const QuesNav = props => (
    <PaginationItem className={props.statequesnum===props.questionnum?"active":"disabled"} 
        onClick={()=>{props.changeques(props.questionnum, props.index)}}>
        <PaginationLink>
        {props.questionnum}
        </PaginationLink>
    </PaginationItem>
)

class WriteResult extends Component {

    constructor(props){
        super(props);
        this.state={
            writing:this.props.writing,
            questionnum:this.props.writing[0].questionnum,
            quesnumindex:0
        }

        this.changeques = this.changeques.bind(this);
        this.quesnav = this.quesnav.bind(this);
        this.questiondetails = this.questiondetails.bind(this);
    }

    quesnav(){
        const ques = [];
        for(let i=0; i<this.state.writing.length;i++){
            if(this.state.writing[i].partcategory==="Writing"){
                ques.push(<QuesNav index={i} statequesnum={this.state.questionnum} 
                    changeques={this.changeques} 
                    questionnum={this.state.writing[i].questionnum}/>)
            }
        }
        return ques;
    }

    changeques(quesnum, index){
        this.setState({
            questionnum:quesnum,
            quesnumindex: index
        })
    }

    questiondetails(){
        return(
            <tbody>
                <tr>
                    <th>Question:</th>
                    <td><div className="passage" dangerouslySetInnerHTML={{ __html: this.state.writing[this.state.quesnumindex].passage }}/></td>
                </tr>
                <tr>
                    <th>Question Type:</th>
                    <td>{this.state.writing[this.state.quesnumindex].rightanswer}</td>
                </tr>
                <tr>
                    <th>Your Answer:</th>
                    <td>{this.state.writing[this.state.quesnumindex].useranswer}</td>
                </tr>
                <tr>
                    <th>Answer Explanation:</th>
                    <td><div className="passage" dangerouslySetInnerHTML={{ __html: this.state.writing[this.state.quesnumindex].answerexpl }}/></td>
                </tr>
            </tbody>
        )
    }


    render() {
        return (
            <div>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <ul style={{padding:"20px 0px 20px 0px", width:"inherit", overflowX:"scroll", display:"flex", listStyleType:"none"}}>
                                    {this.quesnav()}
                        </ul>
                    </Row>
                </CardHeader>
                <table className="result-table">
                    {this.questiondetails()}
                </table>
            </div>
        )
    }
}

export default WriteResult;
