import React from 'react';
import "../testpanel/panel.css";
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';

import{
    Row,
    Col,
    Table
} from "reactstrap";
import EachMatch from './EachMatch';

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class QuesMatch extends React.Component{
    constructor(props){
        super(props)
        this.state={
            questions:this.props.quesdata,
            options:this.props.options
        }
        this.results = [];
        this.loadquesandopt = this.loadquesandopt.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.quesdata !== this.props.quesdata) {
            this.setState({questions: this.props.quesdata});
          }
        if(prevProps.options !== this.props.options) {
            this.setState({options: this.props.options});
          }
        if(prevProps.question !== this.props.question) {
            this.setState({question: this.props.question});
          }
    }

    loadquesandopt(){
        const rows = [];

        const big = (this.state.questions.length>this.state.options.length?"Questions":"Options");

        if(this.state.questions.length===this.state.options.length){
            for(let i=0; i<this.state.options.length; i++){
                for(let j=0;j<this.props.results.length; j++){
                    if(this.props.results[j].question===this.state.questions[i].question
                        && this.props.results[j].parttype===this.props.part.parttype){
                        rows.push(
                            <tr>
                                <td>
                                    
                                    <EachMatch question={this.state.questions[i]} 
                                                resultindex={j}
                                                currentques={this.props.currentques}/>
                                </td>
                                <td>
                                    <DragDropContainer targetKey="options" 
                                        dragData={this.state.options[i]}>
                                            <button className={"optionTD_"+this.props.setting} dangerouslySetInnerHTML={{ __html: this.state.options[i]}} />
                                    </DragDropContainer>
                                </td>
                            </tr>
                        )
                    }
                }
            }
        }
        else if(big==="Options"){
            for(let i=0; i<this.state.options.length; i++){
                if(i>=this.state.questions.length){
                    rows.push(
                        <tr>
                            <td>
                            </td>
                            <td>
                                <DragDropContainer targetKey="options" 
                                    dragData={this.state.options[i]}>
                                        <button className="optionTD" dangerouslySetInnerHTML={{ __html: this.state.options[i]}}/>
                                </DragDropContainer>
                            </td>
                        </tr>
                    )
                }else{
                    for(let j=0;j<this.props.results.length; j++){
                        if(this.props.results[j].question===this.state.questions[i].question
                            && this.props.results[j].parttype===this.props.part.parttype){
                            rows.push(
                                <tr>
                                    <td>
                                        <EachMatch question={this.state.questions[i]} 
                                            resultindex={j}
                                            currentques={this.props.currentques}/>
                                    </td>
                                    <td>
                                        <DragDropContainer targetKey="options" 
                                            dragData={this.state.options[i]}>
                                                <button className="optionTD" dangerouslySetInnerHTML={{ __html: this.state.options[i]}}/>
                                        </DragDropContainer>
                                    </td>
                                </tr>
                            )
                        }
                    }
                }
            }
        }
        else if(big==="Questions"){
            for(let i=0; i<this.state.questions.length; i++){
                if(i>=this.state.options.length){
                    for(let j=0;j<this.props.results.length; j++){
                        if(this.props.results[j].question===this.state.questions[i].question
                            && this.props.results[j].parttype===this.props.part.parttype){
                            rows.push(
                                <tr>
                                    <td>
                                        <EachMatch question={this.state.questions[i]} 
                                            resultindex={j}
                                            currentques={this.props.currentques}/>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            )
                        }
                    }
                }else{
                    for(let j=0;j<this.props.results.length; j++){
                        if(this.props.results[j].question===this.state.questions[i].question
                            && this.props.results[j].parttype===this.props.part.parttype){
                            rows.push(
                                <tr>
                                    <td>
                                        <EachMatch question={this.state.questions[i]} 
                                            resultindex={j}/>
                                    </td>
                                    <td>
                                        <DragDropContainer targetKey="options" 
                                            dragData={this.state.options[i]}>
                                                <button className="optionTD" dangerouslySetInnerHTML={{ __html: this.state.options[i]}}/>
                                        </DragDropContainer>
                                    </td>
                                </tr>
                            )
                        }
                    }
                }
            }
        }

        return rows;
    }

    render(){
        return(
            <>
                <Table className="match-table">
                    <tr>
                        <th>{this.props.queslabel}</th>
                        <th>{this.props.optionlabel}</th>
                    </tr>
                    {this.loadquesandopt()}

                </Table>
            </>
        )
    }
}

export default connect(mapStateToProps,null)(QuesMatch);