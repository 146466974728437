import React, { Component } from 'react'

class BriefResults extends Component {

    constructor(props){
        super(props);
        this.state={
            listening: this.props.listening,
            reading: this.props.reading,
            writing: this.props.writing,
            speaking: this.props.speaking,
            listeningband:this.props.listeningband,
            readingband:this.props.readingband,
            writingband:this.props.writingband,
            speakingband:this.props.speakingband,
            scored:this.props.scored
        }
        this.renderoverallresultrows = this.renderoverallresultrows.bind(this);
    }

    // componentDidUpdate(prevProps){
    //     if(prevProps.listening!==this.props.listening ||
    //         prevProps.reading!==this.props.reading ||
    //         prevProps.writing!==this.props.writing ||
    //         prevProps.speaking!==this.props.speaking ||
    //         prevProps.listeningband!==this.props.listeningband ||
    //         prevProps.readingband!==this.props.readingband ||
    //         prevProps.writingband!==this.props.writingband ||
    //         prevProps.speakingband!==this.props.speakingband ||
    //         prevProps.scored!==this.props.scored){
    //             this.setState({
    //                 listening: this.props.listening,
    //                 reading: this.props.reading,
    //                 writing: this.props.writing,
    //                 speaking: this.props.speaking,
    //                 listeningband:this.props.listeningband,
    //                 readingband:this.props.readingband,
    //                 writingband:this.props.writingband,
    //                 speakingband:this.props.speakingband,
    //                 scored:this.props.scored
    //             })
    //     }
    // }

    renderoverallresultrows(){
        const overallresultrows = [];

        if(this.state.listening.length!==0){
            var totalrightlisten = 0;
            for(let i=0; i<this.state.listening.length; i++){
                totalrightlisten = totalrightlisten+this.state.listening[i].score;
            }
            overallresultrows.push(
                <tr>
                    <th>Listening</th>
                    <td>{this.state.listening.length}</td>
                    <td>{totalrightlisten}</td>
                    <td>{this.state.listeningband}</td>
                </tr>
            )
        }

        if(this.state.reading.length!==0){
            var totalrightreading = 0;
            for(let i=0; i<this.state.reading.length; i++){
                totalrightreading = totalrightreading+this.state.reading[i].score;
            }
            overallresultrows.push(
            <tr>
                    <th>Reading</th>
                    <td>{this.state.reading.length}</td>
                    <td>{totalrightreading}</td>
                    <td>{this.state.readingband}</td>
                </tr>)
        }

        if(this.state.writing.length!==0){
            overallresultrows.push(
            <tr>
                    <th>Writing</th>
                    <td>{this.state.writing.length}</td>
                    <td>-</td>
                    <td>{(this.state.scored)?this.state.writingband:"Unscored Test"}</td>
                </tr>)
        }

        if(this.state.speaking.length!==0){
            overallresultrows.push(
            <tr>
                    <th>Speaking</th>
                    <td>{this.state.speaking.length}</td>
                    <td>-</td>
                    <td>{(this.state.scored)?this.state.speakingband:"Unscored Test"}</td>
                </tr>)
        }

        return overallresultrows;
    }

    render() {
        return (
            <tbody>
                {this.renderoverallresultrows()}
            </tbody>
        )
    }
}

export default BriefResults;