import React from "react";

class TestHeader extends React.Component {
  state = {
    tabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };

  render() {
    return (
      <>
        <div className="header bg-gradient-info" style={{padding:"40px"}}>
          
        </div>
      </>
    );
  }
}

export default TestHeader;
