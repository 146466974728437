import React from "react";
import "../../assets/css/custom.css";
import ReactPlayer from "react-player";
import {Row} from "reactstrap";

class SpeakAudioplayer extends React.Component{

    constructor(props){
        super(props);
        this.state={
            url: props.audiourl,
            volume:this.props.volume
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.volume!==this.props.volume){
            this.setState({volume:this.props.volume})
        }
        if(prevProps.audiourl!==this.props.audiourl){
            this.setState({url:this.props.audiourl})
        }
    }
    render(){

        return(
            <>
                <Row>
                    <ReactPlayer url={this.state.url} 
                    playing 
                    controls 
                    onStart={this.props.Started}
                    volume={this.state.volume}
                    onEnded={this.props.Ended}
                    style={{marginLeft:"10px",display:"none"}}/>
                </Row>
            </>
        )
    }
}

export default SpeakAudioplayer;