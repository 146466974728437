import React from "react";
import axios from "axios";

import { NavLink as NavLinkRRD, Link } from "react-router-dom";

class TestRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            free:this.props.test.free,
            testname:this.props.test.test.testname,
            testid:this.props.test.test._id,
            testpremium:this.props.test.test.premium,
            testtaken:this.props.testtaken
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.test !== this.props.test) {
            this.setState({
                testname:this.props.test.test.testname,
                testid:this.props.test.test._id,
                testpremium:this.props.test.test.premium,
                testtaken:this.props.testtaken,
                free:this.props.test.free,
            });
          }
        
    }

    render(){
        return(
            <>
                {(this.state.free===true || this.state.free===null)&&
                <tr>
                    <th scope="row">{this.state.testname}</th>
                    <td></td>
                    <td></td>
                    {this.state.testtaken.includes(this.state.testid)?                          
                    <td className="text-red">
                    <i className="ni ni-single-copy-04" style={{"marginRight":"10px"}}/>{" "}
                        Test Taken
                    </td>:<Link to={"/test/livetest/"+this.state.testid}>
                        <td className="text-green">
                        <i className="ni ni-single-copy-04" style={{"marginRight":"10px"}}/>{" "}
                        Take Test
                        </td>
                    </Link>
                    }
                </tr>}

                {(this.state.free===false)&&
                <tr className="testfreerow">
                    <th scope="row">{this.state.testname}</th>
                    <td></td>
                    <td></td>
                    <td style={{cursor:"pointer"}} className="text-gray" onClick={()=>{alert("Please UPGRADE to take the test")}}>
                        UPGRADE
                    </td>
                </tr>}

            </>
        )
    }
}
export default TestRow;