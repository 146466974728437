import Profile from "./views/examples/Profile.jsx";
import Register from "./views/examples/Register.jsx";
import Login from "./views/examples/Login.jsx";
import Review from "./views/examples/Review.jsx";
import Blog from "./views/blog/Blog.jsx";

import Course from "./views/course/Course.jsx";
import Tests from "./views/testpanel/Tests.jsx";
import LiveTest from "./views/testpanel/LiveTest.jsx";
import Dashboard from "./views/dashboard/Dashboard.jsx";
import Result from "./views/result/Result.jsx";

import ShowEachResult from "./views/result/ShowEachResult.jsx"
var routes = [
  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/user",
    show: "yes"
  },
  {
    path: "/Course",
    name: "Course",
    icon: "ni ni-book-bookmark text-blue",
    component: Course,
    layout: "/user",
    show: "yes"
  },
  {
    path: "/Tests",
    name: "Tests",
    icon: "ni ni-ruler-pencil text-red",
    component: Tests,
    layout: "/user",
    show: "yes"
  },
  
  {
    path: "/user-profile",
    name: "Flash Cards",
    icon: "ni ni-credit-card text-red",
    component: Profile,
    layout: "/user",
  },
  {
    path: "/blog",
    name: "Blog",
    icon: "ni ni-single-copy-04 text-yellow",
    component: Blog,
    layout: "/user",
    show: "yes"
  },
  {
    path: "/Result",
    name: "Result",
    icon: "ni ni-chart-bar-32 text-green",
    component: Result,
    layout: "/user",
    show: "yes"
  },
  // {
  //   path: "/Upgrade",
  //   name: "Upgrade",
  //   icon: "ni ni-book-bookmark text-blue",
  //   component: Upgrade,
  //   layout: "/user",
  //   show: "no"
  // },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Review,
    layout: "/AdminN"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/livetest/:id",
    component: LiveTest,
    layout: "/test",
  },

  {
    path: "/showeachresult/:id",
    component: ShowEachResult,
    layout: "/user",
  },
  
];
export default routes;
