import React from "react";
import classnames from "classnames";
import {
    Card,
    CardHeader,
    NavLink,
    Nav,
    Row,
    TabContent,
    TabPane,
    Table,
    Pagination, 
    PaginationItem, 
    PaginationLink
}from "reactstrap";
import UserHeader from "../../components/Headers/UserHeader.jsx";

import axios from "axios";
import cookie from "react-cookies";
import Loader from "react-loader-spinner";
import ReactAudioPlayer from 'react-audio-player';

import "../testpanel/panel.css"
import ListenResult from "./ListenResult.jsx";
import ReadResult from "./ReadResult.jsx";
import WriteResult from "./WriteResult.jsx";
import SpeakResult from "./SpeakResult.jsx";

const QuesNav = props => (
    <PaginationItem className={props.index===props.quesnumindex?"active":"disabled"} 
        onClick={()=>{props.changeques(props.questionnum, props.index)}}>
        <PaginationLink>
        {props.questionnum}
        </PaginationLink>
    </PaginationItem>
)

class ShowEachResult extends React.Component{
    constructor(props){
        super(props)
        this.state={
            tabs: 1,
            userid: cookie.load('userid'),
            test:this.props.location.state.result.testname,
            results:this.props.location.state.result.eachresult,
            quesnumindex:0,
            questionnum:this.props.location.state.result.eachresult[0].questionnum,
            listening:[],
            reading:[],
            writing:[],
            speaking:[],
            readingband:0,
            listeningband:0,
            writingband:0,
            speakingband:0,
            overallband:0,
            package:'',
            scored:null,
            isLoading:true,
        }
        this.renderbriefresults=this.renderbriefresults.bind(this);
        this.renderlisteningresults = this.renderlisteningresults.bind(this);
        this.renderreadingresults = this.renderreadingresults.bind(this);
        this.renderquestionresults = this.renderquestionresults.bind(this);
        this.quesnav = this.quesnav.bind(this);
        this.changeques = this.changeques.bind(this);
        this.questiondetails = this.questiondetails.bind(this);
    }
    
    componentDidMount(){
        console.log(this.props.location.state.result);
        this.setState({
            reading: this.state.results.filter((result)=>{return result.partcategory==="Reading"}),
            listening: this.state.results.filter((result)=>{return result.partcategory==="Listening"}),
            writing: this.state.results.filter((result)=>{return result.partcategory==="Writing"}),
            speaking: this.state.results.filter((result)=>{return result.partcategory==="Speaking"})
        },()=>{
            this.setState({isLoading:false})
            if(this.state.reading.length!==0){
                var totalscore = 0;
                for(let i=0; i<this.state.reading.length; i++){
                    totalscore = totalscore + this.state.reading[i].score
                }
                var readingtype = this.state.test.split(" ")[0];
                if(readingtype==="General"){
                    if(totalscore>=6 && totalscore<=8) this.setState({readingband:2.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=9 && totalscore<=11) this.setState({readingband:3},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=12 && totalscore<=14) this.setState({readingband:3.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=15 && totalscore<=18) this.setState({readingband:4},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=19 && totalscore<=22) this.setState({readingband:4.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=23 && totalscore<=26) this.setState({readingband:5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=27 && totalscore<=29) this.setState({readingband:5.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=30 && totalscore<=31) this.setState({readingband:6},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=32 && totalscore<=33) this.setState({readingband:6.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=34 && totalscore<=35) this.setState({readingband:7},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore===36) this.setState({readingband:7.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=37 && totalscore<=38) this.setState({readingband:8},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore===39) this.setState({readingband:8.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore===40) this.setState({readingband:9},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                }else{
                    if(totalscore>=4 && totalscore<=5) this.setState({readingband:2.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=6 && totalscore<=7) this.setState({readingband:3},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=8 && totalscore<=9) this.setState({readingband:3.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=10 && totalscore<=12) this.setState({readingband:4},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=13 && totalscore<=14) this.setState({readingband:4.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=15 && totalscore<=18) this.setState({readingband:5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=19 && totalscore<=22) this.setState({readingband:5.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=23 && totalscore<=26) this.setState({readingband:6},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=27 && totalscore<=29) this.setState({readingband:6.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=30 && totalscore<=32) this.setState({readingband:7},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=33 && totalscore<=34) this.setState({readingband:7.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=35 && totalscore<=36) this.setState({readingband:8},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=37 && totalscore<=38) this.setState({readingband:8.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                    else if(totalscore>=39 && totalscore<=40) this.setState({readingband:9},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.readingband}))});
                }
            }
            if(this.state.listening.length!==0){
                var listentotalscore = 0;
                for(let i=0; i<this.state.listening.length; i++){
                    listentotalscore = listentotalscore + this.state.listening[i].score
                }
                if(listentotalscore>=11 && listentotalscore<=12) this.setState({listeningband:4},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=13 && listentotalscore<=15) this.setState({listeningband:4.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=16 && listentotalscore<=17) this.setState({listeningband:5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=18 && listentotalscore<=22) this.setState({listeningband:5.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=23 && listentotalscore<=25) this.setState({listeningband:6},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=26 && listentotalscore<=29) this.setState({listeningband:6.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=30 && listentotalscore<=31) this.setState({listeningband:7},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=32 && listentotalscore<=34) this.setState({listeningband:7.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=35 && listentotalscore<=36) this.setState({listeningband:8},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=37 && listentotalscore<=38) this.setState({listeningband:8.5},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
                else if(listentotalscore>=39 && listentotalscore<=40) this.setState({listeningband:9},()=>{this.setState(prevState => ({overallband: prevState.overallband+this.state.listeningband}))});
            }
        });
    }

    renderbriefresults(){
        var overallresult = [];
        const overallresultrows = [];

        if(this.state.listening.length!==0){
            var totalrightlisten = 0;
            for(let i=0; i<this.state.listening.length; i++){
                totalrightlisten = totalrightlisten+this.state.listening[i].score;
            }
            overallresultrows.push(
                <tr>
                    <th>Listening</th>
                    <td>{this.state.listening.length}</td>
                    <td>{totalrightlisten}</td>
                    <td>{this.state.listeningband}</td>
                </tr>
            )
        }

        if(this.state.reading.length!==0){
            var totalrightreading = 0;
            for(let i=0; i<this.state.reading.length; i++){
                totalrightreading = totalrightreading+this.state.reading[i].score;
            }
            overallresultrows.push(
            <tr>
                    <th>Reading</th>
                    <td>{this.state.reading.length}</td>
                    <td>{totalrightreading}</td>
                    <td>{this.state.readingband}</td>
                </tr>)
        }

        if(this.state.writing.length!==0){
            overallresultrows.push(
            <tr>
                    <th>Writing</th>
                    <td>{this.state.writing.length}</td>
                    <td>-</td>
                    <td>{(this.state.scored)?this.state.writingband:"Unscored Test"}</td>
                </tr>)
        }

        if(this.state.speaking.length!==0){
            overallresultrows.push(
            <tr>
                    <th>Speaking</th>
                    <td>{this.state.speaking.length}</td>
                    <td>-</td>
                    <td>{(this.state.scored)?this.state.speakingband:"Unscored Test"}</td>
                </tr>)
        }
        return( 
            <tbody>
                {overallresultrows}
            </tbody>
        )
    }

    renderlisteningresults(){
        if(this.state.listening.length>0)
        return <ListenResult listening={this.state.listening}/>
    }

    renderreadingresults(){
        if(this.state.reading.length>0)
        return <ReadResult reading={this.state.reading}/>
    }

    renderwritingresults(){
        if(this.state.writing.length>0)
        return <WriteResult writing={this.state.writing}/>
    }

    renderspeakingresults(){
        if(this.state.speaking.length>0)
        return <SpeakResult speaking={this.state.speaking}/>
    }

    quesnav(){
        const ques = [];
        for(let i=0; i<this.state.results.length;i++){
            if(this.state.results[i].partcategory==="Reading" || this.state.results[i].partcategory==="Listening"){
                ques.push(<QuesNav index={i} 
                    statequesnum={this.state.questionnum} 
                    changeques={this.changeques} 
                    questionnum={this.state.results[i].questionnum}
                    quesnumindex={this.state.quesnumindex}/>)
            }
        }
        return ques;
    }

    changeques(quesnum, index){
        this.setState({
            questionnum:quesnum,
            quesnumindex: index
        },()=>{
            console.log(this.state.quesnumindex)
        })
    }

    renderrightanswer(){
        var rightanswers = ""
        for(let i=0;i<this.state.results[this.state.quesnumindex].rightanswer.length;i++){
            console.log(this.state.results[this.state.quesnumindex].rightanswer[i])
            if(i === this.state.results[this.state.quesnumindex].rightanswer.length-1){
                rightanswers = rightanswers + this.state.results[this.state.quesnumindex].rightanswer[i]
            }
            else{
                rightanswers = rightanswers + this.state.results[this.state.quesnumindex].rightanswer[i] + "/"
            }
        }
        return rightanswers;
    }

    rightorwrong(rightanswers, useranswer){
        var right = false;
        if(useranswer.length>0){
            for(let i=0; i<rightanswers.length; i++){
                if(rightanswers[i].trim().toLowerCase()===useranswer[0].trim().toLowerCase()){
                    right=true;
                    return right;
                }
            }
        }
        return false;
    }

    questiondetails(){
        return(
            <tbody>
                {this.state.results[this.state.quesnumindex].partcategory==="Listening"?
                <tr>
                    <th>Question Audio:</th>
                    <td ><ReactAudioPlayer
                    src={this.state.results[0].audiourl }
                    controls="true"
                  /></td>
                </tr>:
                <tr>
                    <th>Passage:</th>
                    <td ><div className="passage" dangerouslySetInnerHTML={{ __html: this.state.results[this.state.quesnumindex].passage }}/></td>
                </tr>}
                <tr>
                    <th>Question:</th>
                    <td>
                        {this.state.results[this.state.quesnumindex].parttype==="Single Part Multiple Question"?
                        <div className="passage" dangerouslySetInnerHTML={{ __html: this.state.results[this.state.quesnumindex].singlepartques }}/>:
                        <div dangerouslySetInnerHTML={{ __html: this.state.results[this.state.quesnumindex].question }}/>}
                    </td>
                </tr>
                <tr>
                    <th>Right Answer:</th>
                    <td>{this.renderrightanswer()}</td>
                </tr>
                <tr>
                    <th>Your Answer:</th>
                    <td>
                        {this.state.results[this.state.quesnumindex].useranswer} 
                        {this.rightorwrong(this.state.results[this.state.quesnumindex].rightanswer,
                            this.state.results[this.state.quesnumindex].useranswer)?
                            <i class="fas fa-check-circle" style={{marginLeft:"10px", color:"#3BDC36"}}></i>:
                            <i class="fas fa-times-circle" style={{marginLeft:"10px", color:"#FF3E3E"}}></i>}
                    </td>
                </tr>
                {this.state.listening.length===0&&<tr>
                    <th>Answer Explanation:</th>
                    <td><div className="passage" dangerouslySetInnerHTML={{ __html: this.state.results[this.state.quesnumindex].answerexpl }}/></td>
                </tr>}
            </tbody>
        )
    }

    renderquestionresults(){
        return(
            <>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <ul style={{padding:"20px 0px 20px 0px", width:"inherit", overflowX:"scroll", display:"flex", listStyleType:"none"}}>
                                    {this.quesnav()}
                        </ul>
                    </Row>
                </CardHeader>
                <table className="result-table">
                    {this.questiondetails()}
                </table>
            </>
        )
    }


    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
          [state]: index
        });
      };
    
    renderoverallband(){
        if(this.props.location.state.result.testcategory==="Full Length Test"){
            return (this.state.overallband/2);
        }else return(this.state.overallband)
    }

    render(){
        return(
            <>
            <UserHeader />
            <Card className="shadow" style={{marginLeft:"200px",marginTop:"50px", marginRight:"200px"}}>
              <div>
                    <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">Result</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Nav
                                className="nav-fill flex-column flex-md-row"
                                id="tabs-icons-text"
                                pills
                                role="tablist"
                                style={{marginLeft:"20px",marginTop:"30px", alignItems: "center"}}
                            >
                                
                                
                                <NavLink
                                    aria-selected={this.state.tabs === 1}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 1
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 1)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px",  "width":"150px", height:"40px"}}
                                >
                                    <center>Overall Analysis</center>
                                </NavLink>
                                {this.state.listening.length!==0 && <NavLink
                                    aria-selected={this.state.tabs === 2}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 2
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 2)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Listening</center>
                                </NavLink>}
                                {this.state.reading.length!==0 &&<NavLink
                                    aria-selected={this.state.tabs === 3}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 3
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 3)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Reading</center>
                                </NavLink>}
                                {this.state.writing.length!==0 &&<NavLink
                                    aria-selected={this.state.tabs === 4}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 4
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 4)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Writing</center>
                                </NavLink>}
                                {this.state.speaking.length!==0 &&<NavLink
                                    aria-selected={this.state.tabs === 5}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 5
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 5)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Speaking</center>
                                </NavLink>}
                                {(this.state.reading.length>0||this.state.listening.length>0)
                                    &&<NavLink
                                    aria-selected={this.state.tabs === 6}
                                    className={classnames("mb-sm-2 mb-md-2", {
                                    active: this.state.tabs === 6
                                    })}
                                    onClick={e => this.toggleNavs(e, "tabs", 6)}
                                    href="#pablo"
                                    role="tab"
                                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                                >
                                    <center>Question Wise</center>
                                </NavLink>}
                            </Nav>

                            {this.state.isLoading && <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={80}
                                    width={80}
                                    className="iconcontainer"			
                                />}
                            {!this.state.isLoading &&
                            <Card className="shadow" style={{marginLeft:"20px",marginTop:"30px", marginRight:"20px", marginBottom:"20px"}}>
                                <div>
                                    <TabContent activeTab={"tabs" + this.state.tabs}>
                                    
                                    <TabPane tabId="tabs1">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                                <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Total Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{this.renderoverallband()}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                <table className="result-table" >
                                                <thead className="thead-light">
                                                    <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">No. Of Questions</th>
                                                    <th scope="col">No. of Correct Answers</th>
                                                    <th scope="col">Band Score</th>
                                                    </tr>
                                                </thead>
                                                    {this.renderbriefresults()}
                                                </table>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="tabs2">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Listening Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{this.state.listeningband}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                <table className="result-table" >
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Part Type</th>
                                                        <th scope="col">No. of Questions</th>
                                                        <th scope="col">No. of Correct Answer</th>
                                                    </tr>     
                                                </thead>
                                                    {this.renderlisteningresults()}
                                                </table>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="tabs3">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Reading Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{this.state.readingband}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                <table className="result-table" >
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Part Type</th>
                                                        <th scope="col">No. of Questions</th>
                                                        <th scope="col">No. of Correct Answer</th>
                                                    </tr>     
                                                </thead>
                                                    {this.renderreadingresults()}
                                                </table>
                                            </Card>
                                        </div>
                                    </TabPane>

                                    {this.state.writing && <TabPane tabId="tabs4">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Writing Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{(this.state.scored)?this.state.writingband:"Unscored Test"}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                {this.renderwritingresults()}
                                            </Card>
                                        </div>
                                    </TabPane>}

                                    {this.state.speaking && <TabPane tabId="tabs5">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                            <CardHeader className="border-0">
                                                <Row className="align-items-center">
                                                    <div className="col" style={{textAlign:"center"}}>
                                                    <h2 className="mb-0" style={{fontSize:"15px"}}>Speaking Band Score</h2>
                                                    <h1 style={{fontSize:"60px", color:"cornflowerblue"}}>{(this.state.scored)?this.state.writingband:"Unscored Test"}</h1>
                                                    </div>
                                                </Row>
                                                </CardHeader>
                                                {this.renderspeakingresults()}
                                            </Card>
                                        </div>
                                    </TabPane>}

                                    <TabPane tabId="tabs6">
                                    <div className="mb-5 mb-xl-0">
                                            <Card className="shadow">
                                                {this.renderquestionresults()}
                                            </Card>
                                        </div>
                                    </TabPane>

                                    </TabContent>
                                </div>
                            </Card>
                            }
                        </Card>
                    </div>
              </div>
            </Card>
            </>
        );
    }
}
export default ShowEachResult;