import React from "react";
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

class ParaInput extends React.Component{

    constructor(props){
        super(props);
        this.state={
            currentques:this.props.currentques,
            quesnum:this.props.quesnum,
            part:this.props.part,
            useranswer:this.props.results[this.props.resultindex].useranswer,
            result: this.props.results[this.props.resultindex],
        }

        this.onDrop = this.onDrop.bind(this);
        this.textInput = React.createRef();
    }

    componentDidMount(){
        console.log(this.props.results)
        if(this.state.quesnum===this.state.currentques && this.textInput.current!=null){
            this.textInput.current.focus(); 
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.part!==this.props.part || prevProps.quesnum!==this.props.quesnum || prevProps.currentques!==this.props.currentques){
            this.setState({
                quesnum:this.props.quesnum,
                part:this.props.part,
                currentques:this.props.currentques
            },()=>{
                if(this.state.quesnum===this.state.currentques && this.textInput.current!=null){
                    this.textInput.current.focus(); 
                }
            })
        }
        
    }

    onDrop(data){
        this.setState({
            useranswer:data.dragData
        },()=>{
            //this.props.sendResults(this.state.quesnum, this.state.result.rightanswer, this.state.useranswer);
            var results = this.props.results;
            var quesresult = results[this.props.resultindex];
            var rightanswerlower = [];
            for(let i =0 ; i<quesresult.rightanswer.length; i++){
                rightanswerlower.push(quesresult.rightanswer[i].trim().toLowerCase())
            }
            if(rightanswerlower.includes(this.state.useranswer.trim().toLowerCase())){
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=1;
            }else{
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=0;
            }
            results[this.props.resultindex] = quesresult;
            this.props.updateResult(results);
        })
    }

    render(){
        return(
                <DropTarget targetKey="headings" onHit={this.onDrop}>
                    <div className={"fillblanks-ispara-ques_"+this.props.setting}>
                    <input
                        value={this.state.useranswer}
                        name={this.state.quesnum} 
                        placeholder={this.state.quesnum}
                        ref={this.textInput}
                        onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }}
                        autoComplete="off"
                        spellCheck="false"/>
                    </div>
                </DropTarget>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(ParaInput)