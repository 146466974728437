import React from "react";
import axios from "axios";
import '../../../node_modules/video-react/dist/video-react.css';
import {
  Card,
  CardHeader,
  Row,
  Col,
  Table
} from "reactstrap";
// core components
import UserHeader from "../../components/Headers/UserHeader.jsx";
import Loader from "react-loader-spinner";
import cookie from 'react-cookies';
import ResultRow from "./ResultRow.jsx"
class Result extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
        results:[],
        isLoading:true
      }
  this.resultList = this.resultList.bind(this);
}

  async componentDidMount(){
    if (cookie.load('userid')=== undefined){
      this.props.history.push('/auth/login');
    }
    else{
      const userresult = await axios.post("https://api.testacademia.com/user/result/userresult",{userid:cookie.load('userid')});
      this.setState({
        results:userresult.data,
        isLoading:false
      })
    }
  }

  resultList(){
    console.log(this.state.results)
      const resultrow=[];
      for(let i=0 ;i<this.state.results.length; i++){
        resultrow.push(<ResultRow result={this.state.results[i]}/>)
    }
    return resultrow;
  }

  render() {
    return (
      <div>
        <UserHeader />
        {this.state.isLoading && <Loader
                              type="Puff"
                              color="#00BFFF"
                              height={80}
                              width={80}
                              className="iconcontainer"			
                            />}
                          {!this.state.isLoading &&
        <Card className="shadow"  style={{"marginTop":"30px", "marginBottom":"30px","marginLeft":"15px","marginRight":"15px"}}>
        <Row>
        <Col>
        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Test taken</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" >
                            <thead className="thead-light">
                                <tr>
                                <th scope="col" colSpan="3">Test name</th>
                                <th scope="col" colSpan="2">Result</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.resultList()}
                            </tbody>
                            </Table>
            </Col>
            </Row>
            </Card>
  }
    </div>
    );
  }
}

export default Result;
