import React from "react";

import { NavLink as Link } from "react-router-dom";

class ResultRow extends React.Component{

    constructor(props){
        super(props);

        this.state={
            result:this.props.result
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.result !== this.props.result) {
            this.setState({
                result:this.props.result,
            });
          }
    }

    render(){
        return(
            <>
                <tr>
                    <td scope="row">{this.state.result.testname}</td>
                    <td></td>
                    <td></td>
                    <Link to={{pathname:"/user/showeachresult/"+this.state.result._id,
                            state:{result:this.state.result}}}>
                    <td className="text-blue" >
                        <i className="ni ni-bullet-list-67 text-blue" style={{marginRight:"5px"}}/>{" "}
                        Show result
                    </td>
                    </Link>
                </tr>
            </>
        )
    }
}

export default ResultRow;