import React from "react";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
import Switch from "react-switch";
import cookie from "react-cookies"
import Loader from "react-loader-spinner";
import axios from "axios";
import {
  Card,
  CardHeader,
  NavLink,
  Nav,
  Table,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

import UserHeader from "../../components/Headers/UserHeader.jsx";
import TestRow from "./TestRow";
import TestRowFree from "./TestRowFree";

class Tests extends React.Component {

  constructor(props){
    super(props);

    this.state={
      tabs: 1,
      tests:[],
      switched: "General",
      readingrow:[],
      isLoading:true
    }
    this.testtaken=[];
    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.readingTestList = this.readingTestList.bind(this);
    this.listeniningTestList = this.listeniningTestList.bind(this);
    this.writingTestList = this.writingTestList.bind(this);
    this.speakingTestList = this.speakingTestList.bind(this);
    this.fullLengthTestList = this.fullLengthTestList.bind(this);
  }
  
	toggleSwitch (){
		this.setState(prevState => {
			var value = prevState.switched==="Academic"?"General":"Academic";
			return {
				switched: value
			};
		});
	};

async componentDidMount(){
	if (cookie.load('userid')=== undefined){
		this.props.history.push('/auth/login');
	}
	else{
		var userdetails = await axios.post('https://api.testacademia.com/user/upgrade/finduser',{userid:cookie.load('userid')})
    this.testtaken = userdetails.data.testtaken;
    var alltests = await axios.post('https://api.testacademia.com/admin/test/packtests',{packid:userdetails.data.userType})
		this.setState({
			tests:alltests.data,
			isLoading:false
		})
	}
}

readingTestList(switched){
  const readingrow = [];
  if(switched==="Academic"){
    const readingtests = this.state.tests.filter(function (e){
      var testnamesplit = e.test.testname.split(" ");
      return e.test.maincategory==="Reading" && testnamesplit[0]===switched
    });
    readingtests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[2])>parseInt(b.test.testname.split(" ")[2]))?1:-1)
    if(this.state.switched){
      for(let i=0 ;i<readingtests.length; i++){
          readingrow.push(<TestRow test={readingtests[i]} testtaken={this.testtaken}/>)
      }
    }
  }else{
    const readingtests = this.state.tests.filter(function (e){
      var testnamesplit = e.test.testname.split(" ");
      return e.test.maincategory==="Reading" && testnamesplit[0]!=="Academic"
    });
    readingtests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[2])>parseInt(b.test.testname.split(" ")[2]))?1:-1)
    if(this.state.switched){
      for(let i=0 ;i<readingtests.length; i++){
          readingrow.push(<TestRow test={readingtests[i]} testtaken={this.testtaken}/>)
      }
    }
  }  return readingrow;
}

writingTestList(switched){

  const writingrow = [];
  if(switched==="Academic"){
    const writingtests = this.state.tests.filter(function (e){
      var testnamesplit = e.test.testname.split(" ");
      return e.test.maincategory==="Writing" && testnamesplit[0]===switched
    });
    writingtests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[2])>parseInt(b.test.testname.split(" ")[2]))?1:-1)
    if(this.state.switched){
      for(let i=0 ;i<writingtests.length; i++){
          writingrow.push(<TestRow test={writingtests[i]} testtaken={this.testtaken}/>)
      }
    } 
  }else{
    const writingtests = this.state.tests.filter(function (e){
      var testnamesplit = e.test.testname.split(" ");
      return e.test.maincategory==="Writing" && testnamesplit[0]!=="Academic"
    });
    writingtests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[2])>parseInt(b.test.testname.split(" ")[2]))?1:-1)
    if(this.state.switched){
      for(let i=0 ;i<writingtests.length; i++){
          writingrow.push(<TestRow test={writingtests[i]} testtaken={this.testtaken}/>)
      }
    } 
  }
    return writingrow;

}

listeniningTestList(){

  const listenrow = [];
  const listentests = this.state.tests.filter(function (e){
    return e.test.maincategory==="Listening" 
  });
  listentests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[1])>parseInt(b.test.testname.split(" ")[1]))?1:-1)
  if(this.state.switched){
    for(let i=0 ;i<listentests.length; i++){
        listenrow.push(<TestRow test={listentests[i]} testtaken={this.testtaken}/>)
    }
  }
    return listenrow;
}

speakingTestList(){

  const speakrow = [];
  const speaktests = this.state.tests.filter(function (e){
    return e.test.maincategory==="Speaking" 
  });
  speaktests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[2])>parseInt(b.test.testname.split(" ")[2]))?1:-1)
    
    for(let i=0 ;i<speaktests.length; i++){
        speakrow.push(<TestRow test={speaktests[i]} testtaken={this.testtaken}/>)
    }
    return speakrow;
}

fullLengthTestList(switched){
  const fulllengthrow = [];
  if(switched==="Academic"){
    const fulllengthtests = this.state.tests.filter(function (e){
      var testnamesplit = e.test.testname.split(" ");
      return e.test.maincategory==="Full Length Test" && testnamesplit[0]===switched
    });
    fulllengthtests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[3])>parseInt(b.test.testname.split(" ")[3]))?1:-1)
    if(this.state.switched){
      for(let i=0 ;i<fulllengthtests.length; i++){
          fulllengthrow.push(<TestRow test={fulllengthtests[i]} testtaken={this.testtaken}/>)
      }
    }
  }else{
    const fulllengthtests = this.state.tests.filter(function (e){
      var testnamesplit = e.test.testname.split(" ");
      return e.test.maincategory==="Full Length Test" && testnamesplit[0]!=="Academic"
    });
    fulllengthtests.sort((a,b)=>(parseInt(a.test.testname.split(" ")[3])>parseInt(b.test.testname.split(" ")[3]))?1:-1)
    if(this.state.switched){
      for(let i=0 ;i<fulllengthtests.length; i++){
          fulllengthrow.push(<TestRow test={fulllengthtests[i]} testtaken={this.testtaken}/>)
      }
    }
  }
    return fulllengthrow;
}



  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };


  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
                style={{marginLeft:"20px",marginTop:"30px", alignItems: "center"}}
              >
                
                  
                  <NavLink
                    aria-selected={this.state.tabs === 1}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 1)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px",  "width":"150px", height:"40px"}}
                  >
                    <center>Reading</center>
                  </NavLink>
                  <NavLink
                    aria-selected={this.state.tabs === 2}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 2)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>Listening</center>
                  </NavLink>
                  <NavLink
                    aria-selected={this.state.tabs === 3}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 3
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 3)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>Writing</center>
                  </NavLink>
                  <NavLink
                    aria-selected={this.state.tabs === 4}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 4
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 4)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>Speaking</center>
                  </NavLink>
                  <NavLink
                    aria-selected={this.state.tabs === 5}
                    className={classnames("mb-sm-2 mb-md-2", {
                      active: this.state.tabs === 5
                    })}
                    onClick={e => this.toggleNavs(e, "tabs", 5)}
                    href="#pablo"
                    role="tab"
                    style={{"marginRight":"20px", "width":"150px", height:"40px"}}
                  >
                    <center>Full Length Test</center>
                  </NavLink>
              </Nav>
              {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
			{!this.state.isLoading &&
              <Card className="shadow" style={{marginLeft:"20px",marginTop:"30px", marginRight:"20px"}}>
              <div>
                <TabContent activeTab={"tabs" + this.state.tabs}>
                  
                  <TabPane tabId="tabs1">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Reading</h3>
                                </div>
                                <div className="col">
                                  <Row style={{float:"right"}}>
                                  <h3 className="mb-0" style={{color:"#11cdef", marginRight:"10px"}}>Academic</h3>
                                  <Switch onChange={this.toggleSwitch} 
                                          checked={this.state.switched==="General"}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          onColor="#5e72e4"
                                          offColor="#11cdef"/>
                                  <h3 className="mb-0" style={{color:"#5e72e4", marginLeft:"10px"}}>General</h3>
                                  </Row>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col" colSpan="3">Topic</th>
                                <th scope="col" colSpan="2">Test</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.readingTestList(this.state.switched)}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs2">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Listening</h3>
                                </div>
                                {/* {this.state.test}
                                <div className="col">
                                  <Row style={{float:"right"}}>
                                  <h3 className="mb-0" style={{color:"#11cdef", marginRight:"10px"}}>Academic</h3>
                                  <Switch onChange={this.toggleSwitch} 
                                          checked={this.state.switched==="General"}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          onColor="#5e72e4"
                                          offColor="#11cdef"/>
                                  <h3 className="mb-0" style={{color:"#5e72e4", marginLeft:"10px"}}>General</h3>
                                  </Row>
                                </div> */}
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Test</th>
                                </tr>      
                            </thead>
                            <tbody>
                              {this.listeniningTestList()}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs3">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Writing</h3>
                                </div>
                                <div className="col">
                                  <Row style={{float:"right"}}>
                                  <h3 className="mb-0" style={{color:"#11cdef", marginRight:"10px"}}>Academic</h3>
                                  <Switch onChange={this.toggleSwitch} 
                                          checked={this.state.switched==="General"}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          onColor="#5e72e4"
                                          offColor="#11cdef"/>
                                  <h3 className="mb-0" style={{color:"#5e72e4", marginLeft:"10px"}}>General</h3>
                                  </Row>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Test</th>
                                </tr>
                            </thead>
                            <tbody>
                              {this.writingTestList(this.state.switched)}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs4">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Speaking</h3>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Test</th>
                                </tr>
                            </thead>
                            <tbody>
                              {this.speakingTestList()}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                  <TabPane tabId="tabs5">
                  <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                <h3 className="mb-0">Full Length Test</h3>
                                </div>
                                <div className="col">
                                  <Row style={{float:"right"}}>
                                  <h3 className="mb-0" style={{color:"#11cdef", marginRight:"10px"}}>Academic</h3>
                                  <Switch onChange={this.toggleSwitch} 
                                          checked={this.state.switched==="General"}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          onColor="#5e72e4"
                                          offColor="#11cdef"/>
                                  <h3 className="mb-0" style={{color:"#5e72e4", marginLeft:"10px"}}>General</h3>
                                  </Row>
                                </div>
                            </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">Topic</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col">Test</th>
                                </tr>
                            </thead>
                            <tbody>
                              {this.fullLengthTestList(this.state.switched)}
                            </tbody>
                            </Table>
                        </Card>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </Card>}
      </>
    );
  }
}
export default Tests;