import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
// import { NavLink as NavLinkRRD, Link } from "react-router-dom";

import {
  Navbar,
  Container,
  Button,
  Row,
  Col
} from "reactstrap";
import  cookie  from "react-cookies";
import axios from "axios";

class DashboardNavbar extends React.Component {

  constructor(props){
		super(props);
		this.state={
			username:''
		}
		this.deletecookie = this.deletecookie.bind(this);
	}

	async componentDidMount(){
		const user = await axios.post("https://api.testacademia.com/user/upgrade/finduser",{userid:cookie.load('userid')})
		this.setState({
			username:user.data.name
		})
  }
  
  deletecookie(){
    cookie.remove('userid',{path:"/"})
    console.log(cookie.load('userid'))
  }
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Row>
            <Col>
              <Container fluid
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
                {this.props.brandText}
              </Container>  
            </Col>
            <Col>
              <Container className="h4 text-white" style={{width:"max-content", marginLeft:"700px"}}>
                  Welcome {this.state.username}
              </Container>
            </Col>
            <Col>
              <Container >
              <Link
                to="/"
              >
              <i className="ni ni-button-power text-white" style={{paddingRight:"7px"}}  onClick={this.deletecookie} />
              <span style={{color:"white"}} onClick={this.deletecookie}>Logout</span>
              </Link>
              </Container>
            </Col>
          </Row>

          {/* <Container fluid
             className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              {this.props.brandText}
          </Container>
          <Container
             className="h4 text-white" style={{paddingLeft:"400px"}}>
              Welcome Sourab Kumar
          </Container>
          <Container >
            <Link
              to="/"
            >
            <i className="ni ni-button-power text-white" style={{paddingRight:"7px"}}  onClick={this.deletecookie} />
            <span style={{color:"white"}} onClick={this.deletecookie}>Logout</span>
            </Link>
          </Container> */}
        </Navbar>
      </>
    );
  }
}

export default DashboardNavbar;
