import React from "react";

import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import axios from "axios";
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Register extends React.Component {

  constructor(props){
    super(props);

    this.onChangename = this.onChangename.bind(this);
    this.onChangeemail = this.onChangeemail.bind(this);
    this.onChangemobile = this.onChangemobile.bind(this);
    this.onChangepassword = this.onChangepassword.bind(this);
    this.onChangeprivacy = this.onChangeprivacy.bind(this);
    this.onregister = this.onregister.bind(this);

    this.state={
      name: '',
      email: '',
      mobile: '',
      password:'',
      course: 'IELTS',
      isLoading:false,
      privacy:false
    }
  }

  onChangename(e){
    this.setState({
        name: e.target.value
    });
  }

  onChangeemail(e){
    this.setState({
        email: e.target.value
    });
  }

  onChangemobile(e){
    this.setState({
        mobile: e.target.value
    });
  }

  onChangeprivacy(e){
    if(e.target.checked){
    this.setState({
        privacy: true
    });}
    else{
      this.setState({
        privacy:false
    });
  }
  }

  onChangepassword(e){
    this.setState({
        password: e.target.value
    });
  }

  onregister(e){
    e.preventDefault();
    if(this.state.privacy){
    this.setState({isLoading:true})
    const newUser ={
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      password: this.state.password,
      course: this.state.course,
    }

    console.log(newUser);
    axios.post('https://api.testacademia.com/user/auth/register', newUser)
    .then(res => {
        console.log(res.data)
        cookie.save('userid',res.data,{path:"/",maxAge:10800})
        if(res.data!=='Email already exists'){
          this.setState({isLoading:false})
          this.props.history.push('/user/Dashboard');}
        else{
          alert(res.data);
          this.setState({isLoading:false})
          this.props.history.push('/');
          }  
        })
    .catch(e=>{alert(e)})

    this.setState({
        name: '',
        password:'',
        email:'',
        mobile:'',
        course:null
      })
    }
    else{
      alert("Accept terms and condition")
    }
    }


  render() {
    return (
      <>
      {this.state.isLoading && <Loader
        type="Puff"
        color="#00BFFF"
        height={80}
        width={80}
        className="iconcontainer"			
      />}
    {!this.state.isLoading &&
        <Col lg="5" md="7" style={{"position":"relative", "left":"300px"}}>
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-3">
                <img src={require("../../assets/img/aspirelogo.png")} style={{"width":"200px"}} alt=""/>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign up with credentials</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Name" type="text" onChange = {this.onChangename} value={this.state.name}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" onChange = {this.onChangeemail} value={this.state.email}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Mobile" type="text" onChange = {this.onChangemobile} value={this.state.mobile}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" onChange = {this.onChangepassword} value={this.state.password}/>
                  </InputGroup>
                </FormGroup>
                <tr>
                    <td>
                        <input 
                                style={{width:'50px'}}
                                type="checkbox" 
                                name="privary" 
                                value={this.state.privacy}
                                onChange={this.onChangeprivacy}
                                  />
                        <a href={'http://localhost:3001/privacypolicy'} style={{color:"black"}}>Accept Terms and conditions</a>
                    </td>
                </tr>
                <div className="text-center">
                 
                  <Button className="mt-4" color="primary" type="button" onClick={this.onregister}>
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>}
      </>
    );
  }
}
export default Register;