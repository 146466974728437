import React from "react";
import axios from "axios";
import '../../../node_modules/video-react/dist/video-react.css';
import {
  Card,
  CardHeader,
  Label,
  Row,
  Col,
  CardTitle,
  CardText,
  Table
} from "reactstrap";
// core components
import UserHeader from "../../components/Headers/UserHeader.jsx";

import SingleVideo from "../course/SingleVideo";
import FreeVideoRow from "../course/FreeVideoRow";
import cookie from 'react-cookies';
import Loader from "react-loader-spinner";

class Course extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      videos: [],
	  currentvideo:'',
      singlevideo:{
        videoname:"Testacademia IELTS",
        videodesc:"The videos covering all the modules in IELTS with Grammar, are structured in depth elucidating  the crucial concepts in each section and focusing on their correct application in practice tests. Every detail is elaborated with examples and exercises towards enhanced comprehension and implementation of concepts learnt, enabling students to STUDY AT THEIR OWN PACE and accomplish their desired scores in least possible time.",
        videourl:"https://player.vimeo.com/external/434395242.m3u8?s=b0a569003520a03a181e3062eb8d1f5ec1742ce9"
      },
      isLoading:true
  }

  this.videoList = this.videoList.bind(this);
  this.loadVideo = this.loadVideo.bind(this);
  this.getOnClickVideoId = this.getOnClickVideoId.bind(this);
  }

  componentDidMount(){
    if (cookie.load('userid')=== undefined){
      this.props.history.push('/auth/login');
    }
    else{
    axios.post('https://api.testacademia.com/user/upgrade/finduser',{userid:cookie.load('userid')})
    .then(res => {
          console.log(res.data.userType)
            axios.post('https://api.testacademia.com/admin/package/findpackagevideos',{package:res.data.userType})
            .then(result=>{
				console.log(result.data)
              this.setState({videos:result.data},()=>{
                  this.setState({isLoading:false})    
			  });
			})
            .catch(e=>console.log(e))
        })
        .catch(function(error){
            console.log(error);
        });
      }
  }

  getOnClickVideoId(videoindex){
    this.setState({
      currentvideo:videoindex,
      singlevideo:this.state.videos[videoindex].video
    })
  }

  videoList(){
    const ctx = this;
	const readingrows = [];
    const speakingrows = [];
    const listeningrows = [];
    const writingrows = [];
    const grammarrows = [];
    const videorows = [];

    if(this.state.videos){
		this.state.videos.map(function(currentVideo, i){
			if(currentVideo){
				if(currentVideo.video.subheading === "Speaking" ){
					speakingrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
				}
					
				else if(currentVideo.video.subheading === "Reading" ){
					readingrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
				}
		
				else if(currentVideo.video.subheading === "Listening" ){
					listeningrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
				}
		
				else if(currentVideo.video.subheading === "Writing" ){
					writingrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
				}
		
				else if(currentVideo.video.subheading === "Grammar" ){
						grammarrows.push(<FreeVideoRow video={currentVideo} index={i} getOnClickVideoId={ctx.getOnClickVideoId} />);
				} 
			}
		});
	}
	if(writingrows.length!==0){
		videorows.push(
			<>
				<CardHeader className="border-0">
					<Row className="align-items-center">
						<div className="col">
							<h3 className="mb-0">Writing</h3>
						</div>
						<div className="col text-right">
						</div>
					</Row>
				</CardHeader>
				{writingrows}
			</>
		)
	}
	if(readingrows.length!==0){
		videorows.push(
			<>
				<CardHeader className="border-0">
					<Row className="align-items-center">
						<div className="col">
							<h3 className="mb-0">Reading</h3>
						</div>
						<div className="col text-right">
						</div>
					</Row>
				</CardHeader>
				{readingrows}
			</>
		)
	}
	if(speakingrows.length!==0){
		videorows.push(
			<>
					<CardHeader className="border-0">
						<Row className="align-items-center">
							<div className="col">
								<h3 className="mb-0">Speaking</h3>
							</div>
							<div className="col text-right">
							</div>
						</Row>
					</CardHeader>
					{speakingrows}
			</>
		)
	}
	if(listeningrows.length!==0){
		videorows.push(
			<>
				<CardHeader className="border-0">
					<Row className="align-items-center">
						<div className="col">
							<h3 className="mb-0">Listening</h3>
						</div>
						<div className="col text-right">
						</div>
					</Row>
				</CardHeader>
				{listeningrows}
			</>
		)
	}
	if(grammarrows.length!==0){
		videorows.push(
			<>
				<CardHeader className="border-0">
					<Row className="align-items-center">
						<div className="col">
							<h3 className="mb-0">Grammar</h3>
						</div>
						<div className="col text-right">
						</div>
					</Row>
				</CardHeader>
				{grammarrows}
			</>
		)
	}
	return videorows
  }

  loadVideo(){
    return (<SingleVideo video={this.state.singlevideo}/>);
  }

  render() {
    return (
      <div>
        <UserHeader />
        {/* Page content */}
        {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
          className="iconcontainer"			
				/>}
			{!this.state.isLoading &&
      <Row>
        <Col >
          {this.loadVideo()}
        </Col>
        <Col style={{marginTop:"30px", height:"550px", overflowY:"scroll"}}>
          <Col className="" >
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">IELTS Course Videos</h3>
                    </div>
                    <div className="col text-right">
                    </div>
                  </Row>

                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    {this.videoList()}
                  </tbody>
                </Table>
              </Card>
            </Col>
        </Col>
        </Row>}
    </div>
    );
  }
}

export default Course;
