import React, { Component } from 'react'

class ListenResult extends Component {

    constructor(props){
        super(props);
        this.state={
            listening:this.props.listening,
        }

        this.mcqsr = this.state.listening.filter((result) => {return result.parttype==="MCQ Single Right"});
        this.mcqmr = this.state.listening.filter((result) => {return result.parttype==="MCQ Multiple Right"});
        this.fillblanks = this.state.listening.filter((result) => {return result.parttype==="Fill Blanks"})
        this.matrix = this.state.listening.filter((result) => {return result.parttype==="Matrix"})
        this.heading = this.state.listening.filter((result) => {return result.parttype==="List of Headings"})
        this.matching = this.state.listening.filter((result) => {return result.parttype==="Matching"})        
        this.singlepart = this.state.listening.filter((result) => {return result.parttype==="Single Part Multiple Question"})
        this.writing = this.state.listening.filter((result) => {return result.parttype==="Writing"})
        this.speaking = this.state.listening.filter((result) => {return result.parttype==="Speaking"})
        this.cuecard = this.state.listening.filter((result) => {return result.parttype==="Cue Card Speaking"})
        this.parahead = this.state.listening.filter((result) => {return result.parttype==="Paragraph Headings"})
        this.labelblanks = this.state.listening.filter((result) => {return result.parttype==="Label Blanks"})
        this.labelheading = this.state.listening.filter((result) => {return result.parttype==="Label Headings"})
        this.mcqsrscore=0;
        this.mcqmrscore=0; 
        this.fillblanksscore=0; 
        this.matrixscore=0; 
        this.headingscore=0; 
        this.matchingscore=0;
        this.singlepartscore=0;
        this.labelblanksscore=0;
        this.labelheadingscore=0;
        
    }

    componentDidUpdate(prevProps){
        if(this.props.listening!==prevProps.listening){
            this.setState({
                listening:this.props.listening
            })
        }
    }

    componentDidMount(){
        for(let i =0; i<this.mcqsr.length; i++){
            this.mcqsrscore=this.mcqsrscore+this.mcqsr[i].score
        }
        for(let i =0; i<this.mcqmr.length; i++){
            this.mcqmrscore=this.mcqmrscore+this.mcqmr[i].score
        }
        for(let i =0; i<this.fillblanks.length; i++){
            this.fillblanksscore=this.fillblanksscore+this.fillblanks[i].score
        }
        for(let i =0; i<this.matrix.length; i++){
            this.matrixscore=this.matrixscore+this.matrix[i].score
        }
        for(let i =0; i<this.heading.length; i++){
            this.headingscore=this.headingscore+this.heading[i].score
        }
        for(let i =0; i<this.matching.length; i++){
            this.matchingscore=this.matchingscore+this.matching[i].score
        }
        for(let i =0; i<this.singlepart.length; i++){
            this.singlepartscore=this.singlepartscore+this.singlepart[i].score
        }
        for(let i =0; i<this.writing.length; i++){
            this.writingscore=this.writingscore+this.writing[i].score
        }
        for(let i =0; i<this.speaking.length; i++){
            this.speakingscore=this.speakingscore+this.speaking[i].score
        }
        for(let i =0; i<this.cuecard.length; i++){
            this.cuecardscore=this.cuecardscore+this.cuecard[i].score
        }
        for(let i =0; i<this.labelblanks.length; i++){
            this.labelblanksscore=this.labelblanksscore+this.labelblanks[i].score
        }
        for(let i =0; i<this.labelheading.length; i++){
            this.labelheadingscore=this.labelheadingscore+this.labelheading[i].score
        }
        for(let i =0; i<this.parahead.length; i++){
            this.paraheadscore=this.paraheadscore+this.parahead[i].score
        }
    }

    render() {
        return (
            <tbody>
                {this.mcqsr.length!==0 && <tr>
                    <th>MCQ Single Right</th>
                    <td>{this.mcqsr.length}</td>
                    <td>{this.mcqsrscore}</td>
                </tr>}
                {this.mcqmr.length!==0 && <tr>
                    <th>MCQ Multiple Right</th>
                    <td>{this.mcqmr.length}</td>
                    <td>{this.mcqmrscore}</td>
                </tr>}
                {this.fillblanks.length!==0 && <tr>
                    <th>Fill Blanks</th>
                    <td>{this.fillblanks.length}</td>
                    <td>{this.fillblanksscore}</td>
                </tr>}
                {this.matrix.length!==0 && <tr>
                    <th>Matrix</th>
                    <td>{this.matrix.length}</td>
                    <td>{this.matrixscore}</td>
                </tr>}
                {this.heading.length!==0 && <tr>
                    <th>List of Headings</th>
                    <td>{this.heading.length}</td>
                    <td>{this.headingscore}</td>
                </tr>}
                {this.matching.length!==0 && <tr>
                    <th>Matching</th>
                    <td>{this.matching.length}</td>
                    <td>{this.matchingscore}</td>
                </tr>}
                {this.singlepart.length!==0 && <tr>
                    <th>Single Part Multiple Question</th>
                    <td>{this.singlepart.length}</td>
                    <td>{this.singlepartscore}</td>
                </tr>}
                {this.writing.length!==0 && <tr>
                    <th>Writing</th>
                    <td>{this.writing.length}</td>
                    <td>{this.writingscore}</td>
                </tr>}
                {this.speaking.length!==0 && <tr>
                    <th>Speaking</th>
                    <td>{this.speaking.length}</td>
                    <td>{this.speakingscore}</td>
                </tr>}
                {this.cuecard.length!==0 && <tr>
                    <th>Cue Card Speaking</th>
                    <td>{this.cuecard.length}</td>
                    <td>{this.cuecardscore}</td>
                </tr>}
                {this.parahead.length!==0 && <tr>
                    <th>Paragraph Heading</th>
                    <td>{this.parahead.length}</td>
                    <td>{this.paraheadscore}</td>
                </tr>}
                {this.labelblanks.length!==0 && <tr>
                    <th>Label blanks</th>
                    <td>{this.labelblanks.length}</td>
                    <td>{this.labelblanksscore}</td>
                </tr>}
                {this.labelheading.length!==0 && <tr>
                    <th>Label Heading</th>
                    <td>{this.labelheading.length}</td>
                    <td>{this.labelheadingscore}</td>
                </tr>}
            </tbody>
        )
    }
}

export default ListenResult;