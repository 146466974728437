import React from "react";
import {
  Row,
  Col,
  Modal,
  Input
} from "reactstrap";
import axios from "axios";
import cookie from "react-cookies";
import UserHeader from "../../components/Headers/UserHeader.jsx";
import Upgrade from './Upgrade.jsx';
import Linechart from "./Linechart.jsx";
import Barchart from "./Barchart.jsx";
import Loader from "react-loader-spinner";

import "../../assets/css/custom.css";
import { Link } from "react-router-dom";
var dateFormat = require('dateformat');

class Dashboard extends React.Component {

	constructor(props){
		super(props);
		this.state={
			userdata:[],
			package:[],
			defaultModal: false,
			remainingdays:0,
			isLoading:true,
			results:[],
			
			selectedcategory:'Reading'
		}

		this.changecategory = this.changecategory.bind(this);
	}

	toggleModal = state => {
		this.setState({
		defaultModal: !this.state.defaultModal
		});
	};

	async componentDidMount(){
		if (cookie.load('userid')=== undefined){
			this.props.history.push('/auth/login');
		}
		else{
			const user = await axios.post("https://api.testacademia.com/user/upgrade/finduser",{userid:cookie.load('userid')})
			const userpackage = await axios.post('https://api.testacademia.com/admin/package/onepackage/'+user.data.userType);
			const userresult = await axios.post("https://api.testacademia.com/user/result/userresult",{userid:cookie.load('userid')});
			this.setState({
				userdata:user.data,
				package:userpackage.data,
				results:userresult.data,
				isLoading:false
			})
		}
	}

	changecategory(evt){
		this.setState({
			selectedcategory:evt.target.value
		},()=>{
			console.log(this.state.selectedcategory)
		})
	}

	render() {
		return (
		<>
			
			<UserHeader/>
			{/* Page content */}
			{this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
			{!this.state.isLoading &&
			<Col>
				{this.state.package.packagename==="Free Trial" && <Row style={{backgroundColor:"rgb(236, 250, 162)",}}>
				<div style={{ margin:'0 auto',color:"black",padding:'8px',display:'flex'}}>
					{((new Date(this.state.userdata.endDate.split("T")[0]).valueOf()-(new Date().valueOf()))/86400000) >0 ? 
					<div>You have {Math.ceil((new Date(this.state.userdata.endDate.split("T")[0]).valueOf()-(new Date().valueOf()))/86400000)} days left in your free trial.</div>:
					<div>Your free trial is over. </div> } 
					<div onClick ={() => this.toggleModal("defaultModal")}> <u style={{marginLeft:"5px",marginRight:"5px"}}> Click  here </u></div> to upgrade for over 2000 questions and 100 video lessons.
				</div>
				</Row>}
				<Row className="complete-row" >
					<div className="top-side-container">
						<Row>
							<Link to="/user/Tests">
							<div className="taketest-button">
								<div className="taketest-head">
									<i className="fa fa-pencil-alt"></i>
									<div>
										<h2>Take Test Now</h2>
										<p>
											{this.state.package.scored.length+
												this.state.package.unscored.length+
												this.state.package.sectional.length} Tests</p>
									</div>
								</div>
							</div>
							</Link>
							<Link to="/user/Course">
							<div className="watchcourse-button">
								<div className="taketest-head">
									<i className="fa fa-video"></i>
									<div>
										<h2>Watch Course Now</h2>
										<p>{this.state.package.videos.length} Videos</p>
									</div>
								</div>
							</div>
							</Link>
							<div className="upgrade-button" onClick ={() => this.toggleModal("defaultModal")}>
									<div className="taketest-head">
										<i className="fa fa-credit-card"></i>
										<div>
											<h2>Upgrade Now</h2>
											<p>Upgrade Now</p>
										</div>
									</div>
							</div>
							<div className="profile-card">
								<div className="profile-head">
									<i className="fa fa-user-circle"></i>
									<div>
										<h2>{this.state.userdata.name}</h2>
										<p>Last Login: {dateFormat(this.state.userdata.ts, "mmmm dS, yyyy")}</p>
										<p>Package End: {dateFormat(this.state.userdata.endDate, "mmmm dS, yyyy")}</p>
									</div>
								</div>
							</div>
						</Row>
					</div>
				</Row>
					
				<Row className="select-testtype">
						<h4>Type Of Test :</h4>
						<Input type="select" name="select" onChange={(evt)=>{this.changecategory(evt)}}>
							<option value="Reading">Reading</option>
							<option value="Listening">Listening</option>
							<option value="Writing">Writing</option>
							<option value="Speaking">Speaking</option>
							<option value="Full Length Test">Full Length Test</option>
						</Input>
				</Row>
				<Row className="complete-row" >
					<div className="bottom-side-container">
						<Row>
							<div className="linechart-container">
								<h2>Test Performance</h2>
								<Linechart results={this.state.results} selectedcategory={this.state.selectedcategory}/>
							</div>
							<div className="barchart-container">
								<h2>Part Analysis</h2>	
								<Barchart results={this.state.results} selectedcategory={this.state.selectedcategory}/>
							</div>
						</Row>
					</div>
				</Row>
			</Col>}
			<Modal
				className="modal-dialog-centered"
				isOpen={this.state.defaultModal}
				toggle={() => this.toggleModal("defaultModal")}
				style={{maxWidth:"fit-content"}}>
					<div className="modal-header" >
						<Upgrade />
						<button
							aria-label="Close"
							className="close"
							data-dismiss="modal"
							type="button"
							onClick={() => this.toggleModal("defaultModal")}>
							<span aria-hidden={true}>×</span>
						</button>
					</div>
			</Modal>
		</>
		);
	}
}
export default Dashboard;