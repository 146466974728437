import React  from "react";
import "./Upgrade.css";
import axios from "axios";
import cookie from "react-cookies"

require('dotenv').config();
var u ='';
class Packageblock extends React.Component{
    constructor(props){
        super(props);
        this.state={
            packagebought:"",
            discountedprice:0,
            user : ""
        }
        this.options = {
            "key": process.env.REACT_APP_razorpay_id,
            "amount": 0, // 2000 paise = INR 20, amount in paisa
            "name": "",
            "description": "",
            'order_id':"",
            "handler": function(response) {
                console.log(response);
                if(u ){
                var values ={
                    razorpay_signature : response.razorpay_signature,
                    razorpay_order_id : response.razorpay_order_id,
                    transact :{
                        transactionid:response.razorpay_payment_id,
                        transactionamount:props.package.price,
                        userid:u._id,
                        name: u.name,
                        email: u.email,
                        mobile: u.mobile,
                        institutename:u.institutename,
                        packagebought:props.package._id,
                    }
                }
                axios.post('https://api.testacademia.com/user/upgrade/payment',values)
                        .then(res=>{console.log(res.data.transaction.packagebought)

                        axios.post('https://api.testacademia.com/user/upgrade/editstud/'+u._id,{userType:res.data.transaction.packagebought})
                        .then(result=>{console.log(result.data)})
                        .catch(err=>{console.log(err)})
                    })
                .catch(e=>console.log(e))
            }},
            "prefill":{
                "name":'',
                "email":'',
                "contact":'',
            },
            "theme": {
              "color": "#528ff0"
            }
          };
         this.openPayModal = this.openPayModal.bind(this);
    }
    componentDidMount () {
        const script = document.createElement("script");

        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        var discount = (this.props.package.discount*this.props.package.price)/100;
        this.setState({
            discountedprice: this.props.package.price - discount
        })      
        axios.post('https://api.testacademia.com/user/upgrade/finduser',{userid:cookie.load('userid')})
        .then(res => {
            u = res.data;
            this.options.name = res.data.name;
            this.options.prefill.name =res.data.name;
            this.options.prefill.email = res.data.email;
            this.options.prefill.contact = res.data.mobile;
            this.setState({
                user : u
            })
        })
        .catch(e=>{console.log(e)})
        document.body.appendChild(script);
    }
    
    openPayModal(amt){
        var amount = amt * 100;
        console.log(amount);

        axios.post('https://api.testacademia.com/user/upgrade/order',{amount:amount})
        .then(res=>{
            this.options.order_id = res.data.id;
            this.options.amount = res.data.amount;
            console.log(this.options)
            var rzp1 = new window.Razorpay(this.options);
            rzp1.open();
        })
        .catch(e=>console.log(e))
        
    };
    render(){
        return(
            <>
            {this.props.package._id !== this.state.user.userType &&
            <div className="single-pricing-card">
            <div className="time-pricing-card">
                <h3>{this.props.package.validity} Days</h3>
            </div>
            <div className="rate-pricing-card">
                <h3>RS.{this.state.discountedprice}/-</h3>
                {this.props.package.discount !== 0 && <p>RS.{this.props.package.price} /-</p>}
            </div>
            {this.props.package.discount !== 0 && <div className="discount-pricing-card">
                <p>Discount: </p><strong>{this.props.package.discount}%</strong>
            </div>}
            <div className="features-list-card">
            <h4>CONTENT</h4>
                <div className= {this.props.package.videolesson.split(" ")[0].toLowerCase() === "strike" ?
                                    "not-feture-point":"feture-point"}>
                    <i className={this.props.package.videolesson.split(" ")[0].toLowerCase() === "strike" ?
                                    "fa fa-times":"fa fa-check"}></i>
                    <p>Over {this.props.package.videolesson.split(" ")[0].toLowerCase() === "strike" ? 
                            this.props.package.videolesson.split(" ")[1]:
                            this.props.package.videolesson.split(" ")[0]} video lessons</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>Over {this.props.package.practiceques} practice questions</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>50+ grammar videos</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>Explanation videos for every question</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>Up to {this.props.package.scoredflt} scored full-length mock tests</p>
                </div>
                <div className= {this.props.package.livesess.split(" ")[0].toLowerCase() === "strike" ?
                                    "not-feture-point":"feture-point"}>
                    <i className={this.props.package.livesess.split(" ")[0].toLowerCase() === "strike" ?
                                    "fa fa-times":"fa fa-check"}></i>
                    <p>{this.props.package.livesess.split(" ")[0].toLowerCase() === "strike" ? 
                            this.props.package.livesess.split(" ")[1]:
                            this.props.package.livesess.split(" ")[0]} hours of live sessions</p>
                </div>
                <h4>FEATURES</h4>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>Study schedule</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>{this.props.package.validity} days of access</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>Post Test Consultation & SWOT Analysis</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>24/7 Online Tutor Support</p>
                </div>
                <div className="feture-point">
                    <i className="fa fa-check"></i>
                    <p>Pause your plan</p>
                </div>
                <div className={this.props.package.scorepred === 'true'?"feture-point":"not-feture-point"}>
                    <i className={this.props.package.scorepred === "true"?"fa fa-check":"fa fa-times"}></i>
                    <p>Score Predictor</p>
                </div>
            </div>
            <div className="signup-container" onClick={()=>{this.openPayModal(this.state.discountedprice)}}>
                <div className="signup-btn" >
                    Upgrade
                </div>
            </div>
            </div>}
        {this.props.package._id === this.state.user.userType &&
        <div className="active-single-pricing-card">
        <div className="time-pricing-card">
            <h3>{this.props.package.validity} Days</h3>
        </div>
        <div className="rate-pricing-card">
            <h3>RS. {this.state.discountedprice}/-</h3>
            {this.props.package.discount !== 0 && <p>RS.{this.props.package.price} /-</p>}
        </div>
        {this.props.package.discount !== 0 && <div className="discount-pricing-card">
            <p>Discount: </p><strong>{this.props.package.discount}%</strong>
        </div>}
        <div className="features-list-card">
        <h4>CONTENT</h4>
            <div className= {this.props.package.videolesson.split(" ")[0].toLowerCase() === "strike" ?
                                "not-feture-point":"feture-point"}>
                <i className={this.props.package.videolesson.split(" ")[0].toLowerCase() === "strike" ?
                                "fa fa-times":"fa fa-check"}></i>
                <p>Over {this.props.package.videolesson.split(" ")[0].toLowerCase() === "strike" ? 
                        this.props.package.videolesson.split(" ")[1]:
                        this.props.package.videolesson.split(" ")[0]} video lessons</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>Over {this.props.package.practiceques} practice questions</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>50+ grammar videos</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>Explanation videos for every question</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>Up to {this.props.package.scoredflt} scored full-length mock tests</p>
            </div>
            <div className= {this.props.package.livesess.split(" ")[0].toLowerCase() === "strike" ?
                                "not-feture-point":"feture-point"}>
                <i className={this.props.package.livesess.split(" ")[0].toLowerCase() === "strike" ?
                                "fa fa-times":"fa fa-check"}></i>
                <p>{this.props.package.livesess.split(" ")[0].toLowerCase() === "strike" ? 
                        this.props.package.livesess.split(" ")[1]:
                        this.props.package.livesess.split(" ")[0]} hours of live sessions</p>
            </div>
            <h4>FEATURES</h4>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>Study schedule</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>{this.props.package.validity} days of access</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>Post Test Consultation & SWOT Analysis</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>24/7 Online Tutor Support</p>
            </div>
            <div className="feture-point">
                <i className="fa fa-check"></i>
                <p>Pause your plan</p>
            </div>
            <div className={this.props.package.scorepred === 'true'?"feture-point":"not-feture-point"}>
                <i className={this.props.package.scorepred === "true"?"fa fa-check":"fa fa-times"}></i>
                <p>Score Predictor</p>
            </div>
        </div>
        <div className="signup-container" >
            <div className="signup-btn" >
                Active
            </div>
        </div>
    </div>}
            </>
        )
    }
}
export default Packageblock;