import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import UserHeader from "../../components/Headers/UserHeader.jsx";
import {Row} from 'reactstrap';
import cookie from "react-cookies";
import Loader from "react-loader-spinner";

const BlogCard = props => (

    <article className="row blog_item">
        <div className="col-md-3">
            <div className="blog_info text-right">
                <ul className="blog_meta list">
                <li>{props.blog.author}<i className="lnr lnr-user" /></li>
                <li>{props.blog.date.split('T')[0]}<i className="lnr lnr-calendar-full" /></li>
                </ul>
            </div>
            </div>
            <div className="col-md-9">
            <div className="blog_post">
                <img src={props.blog.imageuri} alt="" />
                <div className="blog_details">
                <a href="single-blog.html">
                    <h2>{props.blog.title}</h2>
                </a>
                <p style={{"maxLines": "3","overflow":"hidden", "boxSizing":"content-box", "textOverflow":"ellipsis", "maxHeight":"72px"}}>{props.blog.content}</p>
                <a href={'https://testacademia.com/view/'+props.blog._id} className="blog_btn">View More</a>
                </div>
            </div>
        </div>
    </article>
)

class Blog extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            blogs: [],
            isLoading:true
        };
    }

    componentDidMount(){
        if (cookie.load('userid')=== undefined){
            this.props.history.push('/auth/login');
          }
          else{
        axios.get('https://api.testacademia.com/admin/blog/')
            .then(res => {
                this.setState({blogs: res.data},()=>{
                    this.setState({isLoading:false})});
            })
            .catch(function(error){
                console.log(error);
            });
        }
    }

    componentDidUpdate(){
        axios.get('https://api.testacademia.com/admin/blog/')
            .then(res => {
                this.setState({blogs: res.data},()=>{
                    this.setState({isLoading:false})});
                })
            .catch(function(error){
                console.log(error);
            });
    }

    blogList(){
        var blogrow =[];
        for(var i=this.state.blogs.length-1;i>=0;--i){
            blogrow.push(<BlogCard blog={this.state.blogs[i]} key={i} />);
        }
        return blogrow;
    }

    render(){
        return(
            <>
                <UserHeader />
                {this.state.isLoading && <Loader
					type="Puff"
					color="#00BFFF"
					height={80}
					width={80}
					className="iconcontainer"			
				/>}
			{!this.state.isLoading &&
                <Row style={{"marginTop":"30px"}}>
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-8">
                            <div className="blog_left_sidebar">
                                {this.blogList()}
                            </div>
                        </div>
                        </div>
                    </div>
                </Row>}
            </>
            
        );
    }
}

export default Blog;