import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./panel.css";

import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  Modal,
} from "reactstrap";
import QuestionPara from "../testpanel/QuestionPara";
import WritingPara from "../testpanel/WritingPara";

import QuesMcqSr from "../quescomp/QuesMcqSr";
import QuesMcqMr from "../quescomp/QuesMcqMr";
import QuesMatrix from "../quescomp/QuesMatrix";
import QuesMatch from "../quescomp/QuesMatch";
import QuesListHead from "../quescomp/QuesListHead";
import QuesFillBlanks from "../quescomp/QuesFillBlanks";
import Audioplayer from '../testpanel/Audioplayer';
import WritingQues from '../testpanel/WritingQues';
import SpeakingQues from '../testpanel/SpeakingQues';
import CueCardSpeakingQues from "../testpanel/CueCardSpeakingQues";
import SpeakingQuespara from "../testpanel/SpeakingQuespara"
import "./quesstyle.css";
import QuesParaHead from '../quescomp/QuesParaHead';
import Timer from './Timer';
import LabelBlanks from "./LabelBlanks"
import SinglePartMultiQues from "../quescomp/SinglePartMultiQues"
import SpeakAudioplayer from "./SpeakAudioplayer"
import axios from "axios";
import cookie from "react-cookies";

const PartNav = props => (
    <div className="partnav">
        <p style={{display:"inline"}}>Part</p>
        <p style={{display:"inline", marginLeft:"3px"}}>{props.partindex+1}</p>
    </div>
)

const QuesNav = props => (
    <div className={props.partindex===props.currentpart?"quesnav-active":"quesnav"} 
        onClick={()=>{props.onclickQues(props.partindex)}}>
        {props.ques.questionnum}
    </div>
)

class TestPanel extends React.Component{

    constructor(props){
        super(props)
        this.state={
            parts: this.props.parts,
            currentpart:0,
            currentques:this.props.parts[0].quesdata[0].questionnum,
            startrecord:false,
            defaultModal: false,
            setting:'standard',
            instructionModal:true,
            starttimer:false,
            volume:this.props.volume,
            disablenext:false,
            disableprev:true,
            username:'',
            userid:''
        }

        this.results = this.props.results
        this.bottomnav = this.bottomnav.bind(this);
        this.loadquestionpara = this.loadquestionpara.bind(this);
        this.loadquestions = this.loadquestions.bind(this);
        this.onNextClicked = this.onNextClicked.bind(this);
        this.loadimage = this.loadimage.bind(this);
        this.onPreviousClicked = this.onPreviousClicked.bind(this);
        this.loadaudio = this.loadaudio.bind(this);
        this.loadspeaking = this.loadspeaking.bind(this);
        this.loadwriting = this.loadwriting.bind(this);
        this.onclickQues= this.onclickQues.bind(this);
        this.Ended= this.Ended.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleinstructionModal = this.toggleinstructionModal.bind(this);

        this.onchangesetting = this.onchangesetting.bind(this);

        this.highlightpassage = this.highlightpassage.bind(this);

        this.changeQues = this.changeQues.bind(this);
        this.handleVolume = this.handleVolume.bind(this);
        this.Started = this.Started.bind(this);
    }

    async componentDidMount(){
        const user = await axios.post("https://api.testacademia.com/user/upgrade/finduser",{userid:cookie.load('userid')})
        this.setState({
            username:user.data.name,
            userid:user.data._id
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.volume!==this.props.volume){
            this.setState({volume:this.props.volume})
        }
    }

    onchangesetting(evt){
        this.setState({
            setting:evt.target.value
        })
    }

    toggleinstructionModal = state => {
        this.setState({
          instructionModal: !this.state.instructionModal,
          starttimer:true
        });
      };

    toggleModal = state => {
		this.setState({
		defaultModal: !this.state.defaultModal
		});
	};

    onclickQues(partindex){
        this.setState({
            currentpart:partindex
        },()=>{
            this.setState({
                currentques:this.state.parts[this.state.currentpart].startques
            })
        })
    }

    bottomnav (){
        if(this.state.parts && this.props.testcategory!=="Speaking"){
            const ctx= this;
            var partsarray = this.state.parts;

            var navrows = [];
            for(let i=0; i<partsarray.length; i++){
                var questionarray = partsarray[i].quesdata;
                for(let j=0; j<questionarray.length; j++){
                    if(questionarray[j].questionnum%10===1){
                        navrows.push(<PartNav part={partsarray[i]}
                                        partindex={Math.trunc(questionarray[j].questionnum/10)}/>)
                    }
                    navrows.push(<QuesNav partindex={i} 
                                    currentpart={this.state.currentpart} 
                                    ques={questionarray[j]} 
                                    quesindex={j} 
                                    onclickQues={ctx.onclickQues}/>)
                }
            }

            return  navrows
        }else if(this.state.parts && this.props.testcategory==="Speaking"){
            const ctx= this;
            var partsarray = this.state.parts;
            var navrows = [];
            for(let i=0; i<partsarray.length; i++){
                var questionarray = partsarray[i].quesdata;
                for(let j=0; j<questionarray.length; j++){
                    if(questionarray[j].questionnum===1){
                        navrows.push(<PartNav part={partsarray[i]}
                                        partindex={0}/>)
                    }else if(questionarray[j].questionnum===6){
                        navrows.push(<PartNav part={partsarray[i]}
                            partindex={1}/>)
                    }else if(questionarray[j].questionnum===7){
                        navrows.push(<PartNav part={partsarray[i]}
                            partindex={2}/>)
                    }
                    navrows.push(<QuesNav partindex={i} 
                                    currentpart={this.state.currentpart} 
                                    ques={questionarray[j]} 
                                    quesindex={j} 
                                    onclickQues={ctx.onclickQues}/>)
                }
            }

            return  navrows
        }else{
            return <div></div>
        }
    }

    changeQues(quesnum){
        this.setState({
            currentques: quesnum
        })
    }

    onNextClicked(){
        if(this.state.currentpart<=this.state.parts.length-1 && this.state.currentques<this.state.parts[this.state.parts.length-1].endques){
            this.setState({
                disablenext:false,
                disableprev:false,
                currentques: this.state.currentques+1
            },()=>{
                console.log("Ques "+this.state.currentques)
                if(this.state.currentques>this.state.parts[this.state.currentpart].endques && this.state.currentpart<this.state.parts.length){
                    this.setState({
                        currentpart:this.state.currentpart+1
                    },()=>{
                        console.log("Part "+this.state.currentpart)
                    })
                }
            })
        }else{
            this.setState({
                disablenext:true
            })
        }
    }
    onPreviousClicked(){
        if(this.state.currentpart>=0 && this.state.currentques>1){
            this.setState({
                disableprev:false,
                disablenext:false,
                currentques: this.state.currentques-1
            },()=>{
                console.log("Ques "+this.state.currentques)
                if(this.state.currentques<this.state.parts[this.state.currentpart].startques && this.state.currentpart>0){
                    this.setState({
                        currentpart:this.state.currentpart-1
                    },()=>{
                        console.log("Part "+this.state.currentpart)
                    })
                }
            })
        }else{
            this.setState({
                disableprev:true,
                disablenext:false
            })
        }
    }
    onExitClicked(){
        confirmAlert({
            title: 'Confirm to Exit Test',
            message: 'Are you sure you want to Exit the test?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {this.props.onexitclicked(this.state.parts[0].partcategory)}
              },
              {
                label: 'No'
              }
            ]
        });
    }

    loadspeaking(){
        return <>
                <Row>
                    <Col className="writing-partdetails">
                    <h3>Part {this.state.currentpart+1}</h3>
                    <p dangerouslySetInnerHTML={{ __html: this.state.parts[this.state.currentpart].partinstruction} }/>
                    </Col>
                </Row>
                <Row className="speak-panelcontainer">
                    <Col className="speak-col">  
                        <div>
                            {this.loadquestionpara()}
                        </div>
                        {this.state.parts[this.state.currentpart].audio &&
                        this.state.starttimer &&
                        <SpeakAudioplayer 
                            audiourl={this.state.parts[this.state.currentpart].audio} 
                            Ended={this.Ended}
                            Started={this.Started}
                            volume={this.state.volume}/>}
                        {this.state.parts[this.state.currentpart].parttype ==="Speaking"?
                        <Col >
                            <SpeakingQues 
                                isblocked = {this.props.isblocked}
                                part={this.state.parts[this.state.currentpart]}
                                quesdata={this.state.parts[this.state.currentpart].quesdata}
                                startrecording={this.state.startrecord}/>
                        </Col>:
                        <Col>
                            <CueCardSpeakingQues 
                                isblocked = {this.props.isblocked}
                                part={this.state.parts[this.state.currentpart]}
                                quesdata={this.state.parts[this.state.currentpart].quesdata}
                                startrecording={this.state.startrecord}/>
                        </Col>}
                    </Col>
                 </Row>
                </>
    }

    Started(){
        this.setState({
            startrecord:false
        })
    }

    Ended(){
        this.setState({
            startrecord:true
        })
    }

    loadwriting(){
      return <>
            <Row>
                <Col className="writing-partdetails">
                <h3>Part {this.state.currentpart+1}</h3>
                <p dangerouslySetInnerHTML={{ __html: this.state.parts[this.state.currentpart].partinstruction} }/>
                </Col>
            </Row>
            <Row className="writing-panelcontainer">
                <Col>
                    <Row style={{textAlign:"left"}}>{this.loadquestionpara()}</Row>
                </Col>
                <Col style={{padding:"20px", maxHeight:"800px", overflowY:"scroll"}}>
                    <WritingQues writingques={this.state.parts[this.state.currentpart].quesdata}
                                part={this.state.parts[this.state.currentpart]}/>
                </Col>
            </Row>
            </>

    }


    highlightpassage(selectedstring, note){
        console.log(selectedstring);
        if((note===null || note==='')&& (selectedstring!==null)){
            var htmlstr = this.state.parts[this.state.currentpart].passage
                            .replace(selectedstring,'<span class="highlight">'+selectedstring+"</span>");
            console.log(htmlstr);
            var preparts = {...this.state.parts};
            preparts[this.state.currentpart].passage = htmlstr;
            this.setState({preparts});
        }
        else if((note!==null || note!=='') && (selectedstring!==null)){
            var htmlstrwithnote = this.state.parts[this.state.currentpart].passage
                            .replace(selectedstring,'<span class="highlight">'+'<span aria-label="'+note+'">'+selectedstring+'</span></span>');
            //console.log(htmlstrwithnote)
            var prepartsnote = {...this.state.parts};
            prepartsnote[this.state.currentpart].passage = htmlstrwithnote;
                            this.setState({prepartsnote});
        }
    }

    loadquestionpara(){
        if(this.state.parts[this.state.currentpart].partcategory==="Reading" || 
            this.state.parts[this.state.currentpart].partcategory==="Listening"){
            return (<Col >
            <QuestionPara image={this.state.parts[this.state.currentpart].image} 
                        passage={this.state.parts[this.state.currentpart].passage}
                        part={this.state.parts[this.state.currentpart]}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}
                        highlightpassage={this.highlightpassage}
                        />
            </Col>
            )
        }
        if(this.state.parts[this.state.currentpart].partcategory==="Writing"){
            return (
            <WritingPara image={this.state.parts[this.state.currentpart].image}  
                        passage={this.state.parts[this.state.currentpart].passage}
                        task={this.state.parts[this.state.currentpart].startques}
                        setting ={this.state.setting}/>
            )
        }
        if(this.state.parts[this.state.currentpart].partcategory==="Speaking"){
            return (
            <SpeakingQuespara passage={this.state.parts[this.state.currentpart].passage}
            setting ={this.state.setting}/>
            )
        }
    }
    loadaudio(){
        if (this.state.parts[this.state.currentpart].partcategory==="Listening"){
            return (<Audioplayer audiourl={this.state.parts[this.state.currentpart].audio} 
                    volume={this.state.volume}/>)
        }
    }

    handleVolume(e) {
        this.setState({
            volume: e.target.value,
        },()=>{
            console.log("volume changed")
            this.props.setvolume(this.state.volume);
        });
    }

    loadimage(){
        return(
            <img src={this.state.parts[this.state.currentpart].image} alt="" style={{maxWidth:"300px"}}/>
        )
    }

    loadquestions=(currentpart)=>{
        if(this.state.parts[currentpart].parttype==="MCQ Single Right"){
            return <QuesMcqSr 
                        part={this.state.parts[this.state.currentpart]}
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}
                        changeQues={this.changeQues}/>
        }
        if(this.state.parts[currentpart].parttype==="MCQ Multiple Right"){
            return <QuesMcqMr 
                        part={this.state.parts[this.state.currentpart]}
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}/>
        }
        if(this.state.parts[currentpart].parttype==="Fill Blanks"){
            return <QuesFillBlanks 
                        part={this.state.parts[this.state.currentpart]}
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}/>
        }
        if(this.state.parts[currentpart].parttype==="Matrix"){
            return <QuesMatrix 
                        part={this.state.parts[this.state.currentpart]}
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        startoption={this.state.parts[this.state.currentpart].startoption}
                        endoption ={this.state.parts[this.state.currentpart].endoption}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}/>
        }
        if(this.state.parts[currentpart].parttype==="List of Headings"){
            return <QuesListHead 
                        part={this.state.parts[this.state.currentpart]}
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        listofheadings = {this.state.parts[this.state.currentpart].listofheadings}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}/>
        }
        if(this.state.parts[currentpart].parttype==="Paragraph Headings"){
            return <QuesParaHead
                        part={this.state.parts[this.state.currentpart]}
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        listofheadings = {this.state.parts[this.state.currentpart].listofheadings}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}/>
                        
        }
        if(this.state.parts[currentpart].parttype==="Matching"){
            return <QuesMatch 
                        part={this.state.parts[this.state.currentpart]}
                        queslabel={this.state.parts[this.state.currentpart].queslabel} 
                        optionlabel={this.state.parts[this.state.currentpart].optionlabel} 
                        options={this.state.parts[this.state.currentpart].listofoptions} 
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}/>
        }
        if(this.state.parts[currentpart].parttype==="Single Part Multiple Question"){
            return <SinglePartMultiQues 
                        part={this.state.parts[this.state.currentpart]}
                        options={this.state.parts[this.state.currentpart].listofoptions} 
                        quesdata={this.state.parts[this.state.currentpart].quesdata}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}/>
        }
    }

    

    loadlabelblanks(){
        if(this.state.parts){
            return <LabelBlanks image={this.state.parts[this.state.currentpart].image} 
                        passage={this.state.parts[this.state.currentpart].passage}
                        part={this.state.parts[this.state.currentpart]}
                        currentques = {this.state.currentques}
                        setting ={this.state.setting}
                        highlightpassage={this.highlightpassage}
                        />
        }
    }

    loadlabelheadings(){
        return <QuesParaHead
                part={this.state.parts[this.state.currentpart]}
                quesdata={this.state.parts[this.state.currentpart].quesdata}
                listofheadings = {this.state.parts[this.state.currentpart].listofheadings}
                currentques = {this.state.currentques}
                setting ={this.state.setting}/> 
    }
    

    render(){
        return(
            <>
            <Card className="shadow">
              <div>
                    <div className="mb-5 mb-xl-0">
                        <Card className="shadow">
                            <CardHeader className="border-0" style={{paddingTop:"5px", paddingBottom:"5px"}}>
                            <div style={{paddingLeft:"10px", paddingRight:"10px", display:"flex", flexWrap:"wrap", marginRight:"-15px", marginLeft:"-15px"}} className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">{this.state.username} - {this.state.userid.substr(this.state.userid.length - 7).toUpperCase()}</h3>
                                </div>
                                    <div>
                                        <Timer duration={this.props.duration} 
                                            ontimerstop={this.props.ontimerstop} 
                                            starttimer={this.state.starttimer}
                                            category={this.state.parts[0].partcategory}/>
                                        
                                    </div>
                                    <Button onClick ={() => this.toggleModal("defaultModal")} size="sm">Settings</Button>
                                    <Modal
                                        className="modal-dialog-centered"
                                        isOpen={this.state.defaultModal}
                                        toggle={() => this.toggleModal("defaultModal")}
                                        style={{maxWidth:"fit-content"}}>
                                            <div className="modal-header" >
                                                <h5 className="modal-title" id="exampleModalLabel">
                                                <i className="ni ni-settings" style={{marginRight:'10px'}}/>Settings
                                                </h5>
                                                <button
                                                    aria-label="Close"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    type="button"
                                                    onClick={() => this.toggleModal("defaultModal")}>
                                                    <span aria-hidden={true}>×</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                If you wish, you can change the setting to make the test easier to read.
                                                <h1>Text</h1>
                                                <h2>Size</h2>
                                                <tr>
                                                    <input 
                                                        name="setting" 
                                                        type="radio" 
                                                        value="standard" 
                                                        defaultChecked={this.state.setting==="standard"}
                                                        onChange={this.onchangesetting}/>Standard
                                                </tr>
                                                <tr>
                                                    <input 
                                                        name="setting" 
                                                        type="radio" 
                                                        value="large"
                                                        defaultChecked={this.state.setting==="large"}
                                                        onChange={this.onchangesetting}/>Large
                                                </tr>
                                                <tr>
                                                    <input
                                                         name="setting" 
                                                         type="radio" 
                                                         value="exlarge"
                                                         defaultChecked={this.state.setting==="exlarge"}
                                                         onChange={this.onchangesetting}/>Extra Large
                                                </tr>
                                            </div>
                                            <div className="modal-footer">
                                                <Button
                                                color="secondary"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={() => this.toggleModal("defaultModal")}
                                                >
                                                Close
                                                </Button>
                                            </div>
                                    </Modal>
                                    {/* <Button color="danger" outline type="button" onClick={this.props.ontimerstop}> */}
                                    
                                    <Button color="danger" outline type="button" 
                                        onClick={()=>{this.onExitClicked()}} size="sm">
                                        Exit Test
                                    </Button>

                                    <div className='slider-wrapper'>
                                        <i className="ni ni-notification-70" style={{marginRight:"15px"}}></i>
                                        <input type="range" min='0' max='1' value={this.state.volume} onChange={this.handleVolume} step='0.1' />
                                    </div> 
                            </div>
                            </CardHeader>
                            {this.state.starttimer&&this.loadaudio()}
                            {(this.state.parts[this.state.currentpart].parttype !=="Label Blanks" &&
                                this.state.parts[this.state.currentpart].parttype !=="Label Headings" &&
                                (this.state.parts[this.state.currentpart].partcategory === "Reading" ||
                                this.state.parts[this.state.currentpart].partcategory === "Listening"))&&
                                <Row className="panelcontainer">
                                    {this.state.parts[this.state.currentpart].passage &&
                                    <Col className="paraside">
                                        {this.loadquestionpara()}
                                    </Col>}

                                    <Col className={
                                        (this.state.parts[this.state.currentpart].partcategory === "Listening")&&
                                        !(this.state.parts[this.state.currentpart].passage || this.state.parts[this.state.currentpart].image)
                                        ?"listening-quesside":"quesside"}>
                                    {<div className={"quesside-partdetails_"+this.state.setting}>
                                        <p style={{fontWeight:"bold"}}>Questions {this.state.parts[this.state.currentpart].startques} - {this.state.parts[this.state.currentpart].endques} </p>
                                        <p dangerouslySetInnerHTML={{ __html: this.state.parts[this.state.currentpart].partinstruction} }/>
                                    </div>}
                                    {this.state.parts[this.state.currentpart].ispara ==='yes'?
                                        <div className="paraques">{this.loadquestions(this.state.currentpart)}</div> 
                                        : <div>{this.loadquestions(this.state.currentpart)}</div>}
                                    
                                    </Col>
                                </Row>
                                }

                                {this.state.parts[this.state.currentpart].parttype !=="Label Headings" &&
                                this.state.parts[this.state.currentpart].parttype ==="Label Blanks" && 
                                this.state.parts[this.state.currentpart].partcategory === "Listening" &&
                                    <>
                                        <div className={"listening-quesside"}>
                                            {<div className={"quesside-partdetails_"+this.state.setting}>
                                            <p style={{fontWeight:"bold"}}>Questions {this.state.parts[this.state.currentpart].startques} - {this.state.parts[this.state.currentpart].endques} </p>
                                            <p dangerouslySetInnerHTML={{ __html: this.state.parts[this.state.currentpart].partinstruction} }/>
                                            </div>}
                                        </div>  
                                        <Row className="panelcontainer">
                                            <Col className="paraside">
                                                {this.loadlabelblanks()}
                                            </Col>
                                        </Row>
                                    </>
                                }

                                {this.state.parts[this.state.currentpart].parttype ==="Label Headings" && 
                                this.state.parts[this.state.currentpart].partcategory === "Listening" &&
                                    <>
                                        <div className={"label-quesside"}>
                                            {<div className={"quesside-partdetails_"+this.state.setting}>
                                            <p style={{fontWeight:"bold"}}>
                                                Questions {this.state.parts[this.state.currentpart].startques} - {this.state.parts[this.state.currentpart].endques} 
                                            </p>
                                            <p dangerouslySetInnerHTML={{ __html: this.state.parts[this.state.currentpart].partinstruction} }/>
                                            </div>}
                                        </div>  
                                        <Row className="panelcontainer">
                                            <Col className="paraside">
                                                {this.loadlabelblanks()}
                                            </Col>
                                            <Col>
                                                {this.loadlabelheadings()}
                                            </Col>
                                        </Row>
                                    </>
                                }

                                {this.state.parts[this.state.currentpart].parttype ==="Label Blanks" && 
                                this.state.parts[this.state.currentpart].partcategory === "Reading" &&
                                    <>
                                         
                                        <Row className="panelcontainer">
                                            {this.state.parts[this.state.currentpart].passage &&
                                            <Col className="paraside">
                                                {this.loadquestionpara()}
                                            </Col>}
                                            <Col className="paraside">
                                                <div className={"label-quesside"}>
                                                    {<div className={"quesside-partdetails_"+this.state.setting}>
                                                    <p style={{fontWeight:"bold"}}>
                                                        Questions {this.state.parts[this.state.currentpart].startques} - {this.state.parts[this.state.currentpart].endques} 
                                                    </p>
                                                    <p dangerouslySetInnerHTML={{ __html: this.state.parts[this.state.currentpart].partinstruction} }/>
                                                    </div>}
                                                    {this.loadlabelblanks()}
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            
                                {(this.state.parts[this.state.currentpart].parttype === "Speaking"||this.state.parts[this.state.currentpart].parttype === "Cue Card Speaking") &&
                                 this.loadspeaking()}

                                {this.state.parts[this.state.currentpart].partcategory === "Writing" &&
                                this.loadwriting()} 
                        </Card>
                    </div>
                    <div className="bottom-nav-row" >
                        {this.bottomnav()}
                        {!this.state.disableprev &&
                            <i class="fas fa-arrow-left"
                            onClick={()=>{this.onPreviousClicked()}}/>}
                        {!this.state.disablenext &&
                            <i class="fas fa-arrow-right"
                            onClick={()=>{this.onNextClicked()}}/>}
                    </div>
              </div>
              <Modal
              className="modal-dialog-centered"
              isOpen={this.state.instructionModal}
              toggle={() => this.toggleinstructionModal("instructionModal")}
              style={{maxWidth:"400px"}}
            >
            <div className="modal-header" >
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleinstructionModal("instructionModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
            </div>
              <div className="modal-body" style={{maxWidth:"fit-content"}}>
                  Time to complete your test is {this.props.duration} mins. Please check time count.
              </div>
            <div className="modal-footer">
                <Button
                  className="ml-auto"
                  color="primary"
                  data-dismiss="modal"
                  type="button"
                  size = "sm"
                  onClick={() => this.toggleinstructionModal("instructionModal")}
                >
                  OK
                </Button>
            </div>
            </Modal>
            </Card>

            </>
        )
    }
}

export default TestPanel;