import axios from "axios";
async function evaluateSpeak (userid,test, speakPartList){
    var speakingbandscore = 0;
    const res = await axios.post('https://api.testacademia.com/user/upgrade/finduser',{userid:userid})
    const res2 = await axios.post('https://api.testacademia.com/admin/package/onepackage/'+res.data.userType)
    console.log(res2.data.scored.includes(test._id))

    if(res2.data.scored.includes(test._id)){
        var scoredtest = true;
        var speakPartListResult = speakPartList;
        const headers = {
            'x-api-key': '6ih0Rdi2qm4faked9SbD33mCvgraTca53dZAxCsA',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
            }
        for(let i=0; i<speakPartList.length; i++){
            const res3 = await axios.post("https://api.crucibledesk.com/v1/testacademia-speaking-api",
                        {"url":"https://pte.crucibledesk.com/student-details/782_38/764/blob.wav","text":"String of the audio"},
                        {headers: headers})
            console.log(res3.data);
            speakPartListResult[i].result = res3.data;
            speakingbandscore = speakingbandscore + res3.data["IELTS Speaking Band"];
        }
        return {speakingbandscore:speakingbandscore, scoredtest:scoredtest, result:speakPartListResult}
    }else{
        return {scoredtest:false,result:null}
    }
}

export default evaluateSpeak;