import React from "react";
import { Line } from 'react-chartjs-2';

const options = {     
    scales: {
                 xAxes: [{
                     display: true,
                     scaleLabel: {
                         display: true,
                         labelString: 'Tests',
                         fontColor:'#b2b2b2',
                         fontSize:12
                     },
                     ticks: {
                        fontColor: "#b2b2b2",
                        fontSize: 12
                       }
                 }],
                 yAxes: [{
                     display: true,
                     scaleLabel: {
                         display: true,
                         labelString: 'Band Score',
                         fontColor: '#b2b2b2',
                         fontSize:12
                     },
                     ticks: {
                           fontColor: "#b2b2b2",
                           fontSize: 12
                     }
                 }]
        },
    legend: {
        display: false,
    }
}

class Linechart extends React.Component{

    constructor(props){
        super(props);
        this.state={
            results:this.props.results,
            selectedcategory:this.props.selectedcategory,
            data:null
        }
        this.setdata = this.setdata.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedcategory!==this.props.selectedcategory){
            this.setState({
                results:this.props.results,
                selectedcategory:this.props.selectedcategory
            },()=>{
                this.setdata();
            })
        }
    }

    componentDidMount() {
        this.setdata();
    }

    setdata(){
        var chartlabels = [];
        var chartdata = [];
        var selectedresults = this.state.results
                                .filter((result)=>{
                                    return result.testcategory===this.state.selectedcategory
                                })

        let i =0;
        if(selectedresults.length>=5)
            i=selectedresults.length-5;

        for(i; i<selectedresults.length; i++){
            chartlabels.push("Test "+(i+1));
            chartdata.push(selectedresults[i].bandscore);
        }

        this.setState({
            data:{
                labels: chartlabels,
                datasets: [
                  {
                    label: this.state.selectedcategory,
                    fill: false,
                    lineTension: 0.3,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: '#e84a81',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#e84a81',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#e84a81',
                    pointHoverBorderColor: '#e84a81',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: chartdata
                  }
                ],
            }
        })
    }

    render(){
        return(
            <div>
                <Line ref="chart" data={this.state.data} options={options}/>
            </div>
        )
    }
}

export default Linechart;