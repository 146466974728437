import React from 'react';
import "../testpanel/panel.css";

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

class EachQuesMatrix extends React.Component{

    constructor(props){
        super(props);

        this.state={
            question:this.props.question,
            startoption:this.props.startoption,
            endoption:this.props.endoption,
            showtick:this.props.results[this.props.resultindex].useranswer!=null || this.props.results[this.props.resultindex].useranswer!=="",
            useranswer: this.props.results[this.props.resultindex].useranswer,
            results: this.props.results[this.props.resultindex]
        }

        this.renderquestion = this.renderquestion.bind(this);
        this.onclickoftd = this.onclickoftd.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.question !== this.props.question) {
            this.setState({
                question: this.props.question,
                useranswer: this.props.results[this.props.resultindex].useranswer,
                results: this.props.results[this.props.resultindex],
                showtick: this.props.results[this.props.resultindex].useranswer!=null || this.props.results[this.props.resultindex].useranswer!==""
            });
          }
    }

    onclickoftd=(i)=>{
        this.props.changeques()
        this.setState({
            showtick:true,
            useranswer:String.fromCharCode(i)
        },()=>{
            //this.props.sendResults(this.state.question.questionnum, this.state.question.rightanswer, this.state.useranswer,this.state.question.answerexplain);
            var results = this.props.results;
            var quesresult = results[this.props.resultindex];
            var rightanswerlower = [];
            for(let i =0 ; i<quesresult.rightanswer.length; i++){
                rightanswerlower.push(quesresult.rightanswer[i].trim().toLowerCase())
            }
            if(rightanswerlower.includes(this.state.useranswer.trim().toLowerCase())){
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=1;
            }else{
                quesresult.useranswer = this.state.useranswer;
                quesresult.score=0;
            }
            results[this.props.resultindex] = quesresult;
            this.props.updateResult(results);
        })
    }

    renderquestion(){
        const questionrow = [];
        if(this.state.startoption && this.state.endoption && this.state.question){
            for (let i=this.state.startoption.charCodeAt(0)-1; i<=this.state.endoption.charCodeAt(0); i++){
                if(i<this.state.startoption.charCodeAt(0)){
                    questionrow.push(<>
                        <td style={{border:"1px solid"}} onClick={()=>{this.props.changeques()}}>
                            <div className={"matrixques_"+this.props.setting}>{this.state.question.questionnum}</div>
                        </td>
                        <td style={{border:"1px solid"}} onClick={()=>{this.props.changeques()}}>
                            <div className={"matrixques_"+this.props.setting} dangerouslySetInnerHTML={{ __html: this.state.question.question }}/>
                        </td></>)
                }else{
                    questionrow.push(<td 
                        style={{border:"1px solid"}} 
                        onClick={((e)=>this.onclickoftd(i))}>
                            {(this.state.showtick) && (String.fromCharCode(i)===this.state.useranswer) && <>&#10004;</>}
                        </td>)
                }
            }
            return questionrow;
        }
    }

    render(){
        return(
            <>
                {this.renderquestion()}
            </>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(EachQuesMatrix);