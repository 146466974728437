import React from "react";
import LabelInput from "./LabelInput";

import {connect} from 'react-redux';
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}

class LabelBlanks extends React.Component{

    constructor(props){
        super(props);

        this.state={
            image: this.props.image,
            part: this.props.part,
            coordinate:this.props.part.coordinates
        }
    }

    componentDidMount(){
        console.log(this.state.part.coordinates);
    }

    componentDidUpdate(prevProps){
        if(prevProps.image!==this.props.image || prevProps.part!==this.props.part ||prevProps.part.coordinates!=this.props.part.coordinates){
            this.setState({
                image: this.props.image,
                part: this.props.part,
                coordinate:this.props.part.coordinates
            },()=>{console.log(this.state.part.coordinates);
            })
        }
    }

    renderinputbox(){
        if(this.state.part){
            var inputboxes = [];
            for(let i=this.state.part.startques,j=0; i<=this.state.part.endques; i++,j++){
                for(let k=0; k<this.props.results.length; k++){
                    if(this.props.part.partcategory===this.props.results[k].partcategory &&
                        this.props.results[k].questionnum===i){
                            console.log(this.props.results[k]);
                            inputboxes.push(
                                <LabelInput 
                                        quesnum={i} 
                                        xvalue={this.state.coordinate[j].x} 
                                        yvalue={this.state.coordinate[j].y}
                                        resultindex={k}
                                        parttype={this.props.part.parttype}
                                        currentques = {this.props.currentques}/>
                            )
                        }
                }
                
            }
            return inputboxes;
        }
    }

    render(){
        return(
            <div>
                {this.state.part.partcategory==="Listening" &&
                    <div className={this.state.part.parttype==="Label Headings"?
                                    "panel-labelimage-heading-container":
                                    "panel-labelimage-container"}>
                            <img className="labelimage" src={this.state.image} alt="" 
                                onError={(e)=>{e.target.onerror = null; e.target.src=this.state.image}}/>
                            <div className="inputcontainer">
                                {this.renderinputbox()}
                            </div>
                    </div>
                }
                {this.state.part.partcategory==="Reading" &&
                    <div className="panel-labelimage-heading-container">
                            <img className="labelimage" src={this.state.image} alt=""/>
                            <div className="inputcontainer">
                                {this.renderinputbox()}
                            </div>
                    </div>
                }
                
            </div>
            
        )
    }
}

export default connect(mapStateToProps,null)(LabelBlanks);