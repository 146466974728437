import React from 'react';
import {DragDropContainer, DropTarget} from 'react-drag-drop-container';
import ReactHtmlParser from 'react-html-parser';
import "../testpanel/panel.css";

import {connect} from 'react-redux';
import {
    updateResult
} from "../../redux-actions/actions";
const mapStateToProps = (state) =>{
    return {
        results: state.results
    }
}
const mapDispatchToProps = () =>{
	return{
        updateResult
    }
}

class EachHeading extends React.Component{
    constructor(props){
        super(props);

        this.state={
            currentques:this.props.currentques,
            question: this.props.question,
            useranswer: this.props.results[this.props.resultindex].useranswer,
            results: this.props.results[this.props.resultindex],
            ispara:this.props.ispara
        }
        this.renderquestion = this.renderquestion.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.transform = this.transform.bind(this);

        this.textInput = React.createRef();
    }

    componentDidMount(){
        if(this.state.question.questionnum===this.state.currentques && this.textInput.current!=null){
            this.textInput.current.focus(); 
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.question !== this.props.question || prevProps.currentques!== this.props.currentques) {
            this.setState({
                question: this.props.question,
                useranswer:this.props.results[this.props.resultindex].useranswer,
                ispara:this.props.ispara,
                currentques:this.props.currentques
            });
        }
        if(this.state.question.questionnum===this.state.currentques && this.textInput.current!=null){
            this.textInput.current.focus(); 
        }
    }

    renderquestion(){
        return(<div className={""+(this.state.ispara==="yes"?"fillblanks-ispara-ques_":"fillblanks-ques_")+this.props.setting}>
                        {ReactHtmlParser(this.state.question.question,{transform: this.transform})}
                </div>)
    }

    transform(node) {
        // do not render any <span> tags
        if (node.type === 'tag' && node.name === 'input') {
          return <DropTarget targetKey="headings" onHit={this.onDrop}>
                    <input
                    value={this.state.useranswer}
                    name={this.state.question.questionnum} 
                    placeholder={this.state.question.questionnum}
                    ref={this.textInput}
                    onKeyDown={(e) => { if (e.keyCode === 9) e.preventDefault() }}
                    autoComplete="off"
                    spellCheck="false"/>
                </DropTarget>;
        }
    }

    onDrop(data){
        if(parseInt(data.target.name)===this.state.question.questionnum){
            this.setState({
                useranswer:data.dragData
            },()=>{
                //this.props.sendResults(this.state.question.questionnum, this.state.question.rightanswer, this.state.useranswer,this.state.question.answerexplain);
                var results = this.props.results;
                var quesresult = results[this.props.resultindex];
                var rightanswerlower = [];
                for(let i =0 ; i<quesresult.rightanswer.length; i++){
                    rightanswerlower.push(quesresult.rightanswer[i].trim().toLowerCase())
                }
                if(rightanswerlower.includes(this.state.useranswer.trim().toLowerCase())){
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=1;
                }else{
                    quesresult.useranswer = this.state.useranswer;
                    quesresult.score=0;
                }
                results[this.props.resultindex] = quesresult;
                this.props.updateResult(results);
            })
        }
    }

    render(){
        return(
            <>
                {this.renderquestion()}
            </>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps())(EachHeading);