async function filterread(results, selectedcategory){

    var selectedresults = results.filter((result)=>{return result.testcategory===selectedcategory})

    var chartlabels = [];
    var mcqsrdata = {
        label:"MCQ Single Right",
        backgroundColor: '#EC7063  ',
        hoverBackgroundColor:'#EC7063  ',
        data:[]
    };
    var mcqmrdata = {
        label:"MCQ Multiple Right",
        backgroundColor: '#45B39D ',
        hoverBackgroundColor:'#45B39D  ',
        data:[]
    };
    var fillblanksdata = {
        label:"Fill Blanks",
        backgroundColor: '#F4D03F ',
        hoverBackgroundColor:'#F4D03F  ',
        data:[]
    };
    var matrixdata = {
        label:"Matrix",
        backgroundColor: '#5D6D7E ',
        hoverBackgroundColor:'#5D6D7E  ',
        data:[]
    };
    var headingdata = {
        label:"List of Headings",
        backgroundColor: '#FFC300',
        hoverBackgroundColor:'#FFC300  ',
        data:[]
    };
    var matchingdata = {
        label:"Matching",
        backgroundColor: '#DAF7A6',
        hoverBackgroundColor:'#DAF7A6  ',
        data:[]
    };
    var singlepartmulquesdata = {
        label:"Single Part Multiple Question",
        backgroundColor: '#581845 ',
        hoverBackgroundColor:'#581845  ',
        data:[]
    };
    var labelblanksdata = {
        label:"Label Blanks",
        backgroundColor: '#D7DBDD ',
        hoverBackgroundColor:'#D7DBDD  ',
        data:[]
    };
    var labelheaddata = {
        label:"Label Headings",
        backgroundColor: '#8E44AD ',
        hoverBackgroundColor:'#8E44AD  ',
        data:[]
    };
    var paraheaddata = {
        label:"Paragraph Headings",
        backgroundColor: '#3498DB ',
        hoverBackgroundColor:'#3498DB  ',
        data:[]
    };

    let i =0;
    if(selectedresults.length>=5)
        i=results.length-5
    for(i; i<selectedresults.length; i++){
            chartlabels.push("Test "+(i+1));
            var eachresults = selectedresults[i].eachresult;

            var mcqsr, mcqmr, fillblanks, matrix, heading, matching, singlepartmulques, 
                labelblanks, labelhead, parahead;

            mcqsr = eachresults.filter((result) => {return result.parttype==="MCQ Single Right"});
            mcqmr = eachresults.filter((result) => {return result.parttype==="MCQ Multiple Right"});
            fillblanks = eachresults.filter((result) => {return result.parttype==="Fill Blanks"})
            matrix = eachresults.filter((result) => {return result.parttype==="Matrix"})
            heading = eachresults.filter((result) => {return result.parttype==="List of Headings"})
            matching = eachresults.filter((result) => {return result.parttype==="Matching"})
            singlepartmulques = eachresults.filter((result) => {return result.parttype==="Single Part Multiple Question"})
            labelblanks = eachresults.filter((result) => {return result.parttype==="Label Blanks"})
            labelhead = eachresults.filter((result) => {return result.parttype==="Label Headings"})
            parahead = eachresults.filter((result) => {return result.parttype==="Paragraph Headings"})

            var mcqsrscore=0, mcqmrscore=0, fillblanksscore=0, matrixscore=0, headingscore=0, 
                matchingscore=0, spmqscore=0, labelblankscore=0, labelheadscore=0, paraheadscore=0;

            for(let i =0; i<mcqsr.length; i++){
                mcqsrscore=mcqsrscore+mcqsr[i].score
            }
            for(let i =0; i<mcqmr.length; i++){
                mcqmrscore=mcqmrscore+mcqmr[i].score
            }
            for(let i =0; i<fillblanks.length; i++){
                fillblanksscore=fillblanksscore+fillblanks[i].score
            }
            for(let i =0; i<matrix.length; i++){
                matrixscore=matrixscore+matrix[i].score
            }
            for(let i =0; i<heading.length; i++){
                headingscore=headingscore+heading[i].score
            }
            for(let i =0; i<matching.length; i++){
                matchingscore=matchingscore+matching[i].score
            }
            for(let i =0; i<singlepartmulques.length; i++){
                spmqscore=spmqscore+singlepartmulques[i].score
            }
            for(let i =0; i<labelblanks.length; i++){
                labelblankscore=labelblankscore+labelblanks[i].score
            }
            for(let i =0; i<labelhead.length; i++){
                labelheadscore=labelheadscore+labelhead[i].score
            }
            for(let i =0; i<parahead.length; i++){
                paraheadscore=paraheadscore+parahead[i].score
            }

            mcqsrdata.data.push(mcqsrscore);
            mcqmrdata.data.push(mcqmrscore);
            fillblanksdata.data.push(fillblanksscore);
            matrixdata.data.push(matrixscore);
            headingdata.data.push(headingscore);
            matchingdata.data.push(matchingscore);
            singlepartmulquesdata.data.push(spmqscore);
            labelblanksdata.data.push(labelblankscore);
            labelheaddata.data.push(labelheadscore);
            paraheaddata.data.push(paraheadscore);
        }
    

    return {
        labels:chartlabels,
        datasets:[mcqsrdata,mcqmrdata,fillblanksdata,matrixdata,headingdata,matchingdata,
            singlepartmulquesdata,labelblanksdata,labelheaddata,paraheaddata]
    };
}

export default filterread;