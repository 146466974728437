async function filterwrite(results, selectedcategory){

    var selectedresults = results.filter((result)=>{return result.testcategory===selectedcategory})

    var chartlabels = [];
    var taskachievement = {
        label:"Task Achievement",
        backgroundColor: '#EC7063  ',
        hoverBackgroundColor:'#EC7063  ',
        data:[]
    };
    var grammaticalaccu = {
        label:"Grammatical Range & Accuracy",
        backgroundColor: '#45B39D ',
        hoverBackgroundColor:'#45B39D  ',
        data:[]
    };
    var spellingerror = {
        label:"Spelling errors count",
        backgroundColor: '#F4D03F ',
        hoverBackgroundColor:'#F4D03F  ',
        data:[]
    };
    var linguisticcomplex = {
        label:"Linguistic complexit",
        backgroundColor: '#5D6D7E ',
        hoverBackgroundColor:'#5D6D7E  ',
        data:[]
    };
    var coherence = {
        label:"Coherence & Cohesion",
        backgroundColor: '#8E44AD',
        hoverBackgroundColor:'#8E44AD  ',
        data:[]
    };

    let i =0;
    if(selectedresults.length>=5)
        i=results.length-5
    for(i; i<selectedresults.length; i++){
            chartlabels.push("Test "+(i+1));
            var eachresults = selectedresults[i].eachresult[i];

            taskachievement.data.push(eachresults.result["Task Achievement"]);
            grammaticalaccu.data.push(eachresults.result["Grammatical Range & Accuracy"]);
            spellingerror.data.push(eachresults.result.spelling_errors_count);
            linguisticcomplex.data.push(eachresults.result.lexical_density);
            coherence.data.push(eachresults.result["Coherence & Cohesion"]);
        }
    

    return {
        labels:chartlabels,
        datasets:[taskachievement,grammaticalaccu,spellingerror,linguisticcomplex,coherence]
    };
}

export default filterwrite;