import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  Container,
  Button,
  Row,
  Col
} from "reactstrap";
import  cookie  from "react-cookies";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


class TestNavbar extends React.Component {
  constructor(){
    super();
    this.deletecookie = this.deletecookie.bind(this);
  }
  deletecookie(){
    cookie.remove('userid',{path:"/"})
    console.log(cookie.load('userid'))
  }
  gotodashboard(){
    confirmAlert({
      title: 'Confirm to Exit Test',
      message: 'Are you sure you want to Exit the test?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {this.props.history.push('/user/Dashboard')}
        },
        {
          label: 'No'
        }
      ]
  });
  }
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" style={{height:"50px"}}>
          <Container fluid>
              <img alt="testacademia" src={require("../../assets/img/aspirelogo.png")} style={{height:"40px", marginRight:"20px"}}/>
              <Button style={{marginLeft:"1200px"}} onClick={()=>{this.gotodashboard()}} size="sm">Dashboard</Button>
                <Link
                  to="/"
                >
                <i className="ni ni-button-power text-white" style={{paddingRight:"7px"}}  onClick={this.deletecookie} />
                <span style={{color:"white"}} onClick={this.deletecookie}>Logout</span>
                </Link>
          </Container>
          
        </Navbar>
      </>
    );
  }
}

export default TestNavbar;
